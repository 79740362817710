import authRequest from "../utils/authRequest";


export const getAllsettlement = (
    page: number,
    pageSize: number = 50,
    searchTexts?: any
): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/settlement/all?page=${page}&per_page=${pageSize}&search_text=${searchTexts ? searchTexts : ""}`,
            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};




export const generateSettlementReport = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/generate-report",
            method: "post",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



export const deleteSettlementReport = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/delete-report/" + id,
            method: "delete",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateSettlementRecord = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/edit-report/" + data?.id,
            method: "put",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const downloadSettlementReport = (settlement_id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/generate-excel/" + settlement_id,
            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const checkTransactionAvailableSettlementReport = (settlement_id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/transaction_available/" + settlement_id,
            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



export const DownloadCSVSettlement = (



): Promise<any> => {
    return new Promise((resolve, reject) => {
        //   const data = `${clients?.length > 0 ? ('?clients=' + clients) : "?"}${interval?.length > 0 ? ('&interval=' + interval) : ""}${duration > 0 ? ('&duration=' + duration) : ""}${status?.length > 0 ? ('&status=' + status) : ""}`
        authRequest({
            url: "/api/v1/subscription/download-csv",
            method: "get",
        })
            .then(({ data }) => {
                //console.log('data49', data)
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}



export const getClientPaymentMethodDetailsSettlement = (params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/clients-card-types",
            method: "get",
            params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const approvedSettlement = (data: any,id:any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/settlement/approved-settlement/"+id,
            method: "put",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};