import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes";

//lightning checks
import mainlogo from "../../assets/img/mainlogo.png";
//bm2bank
// import mainlogo from '../../assets/img/Logo-2.png'
//bm2pay
// import mainlogo from '../../assets/img/bm2pay.svg'
import { useParams } from "react-router-dom";

// bm2pay.svg

const Sidebar = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
}) => {
  const { open, onClose } = props;
  const clientId = localStorage.getItem("clietnIds");
  const updatedRoutes = routes.map((route) => {
    if (route.name === "Smart Routing") {
      return {
        ...route,
        isHidden: clientId === null ? true : false,
        hideInSidebar: clientId === null ? true : false,
      };
    }

    return route;
  });
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      {/*lightning*/}
      <div
        className={`ml-[30px] mr-[40px] mt-[47px] flex items-center justify-center`}
      >
        {/* bm2pay */}
        {/* <div
        className={`ml-[30px] mr-[40px] mt-[30px] flex items-center justify-center`}
      > */}
        {/* {lightning} */}
        <img
          src={mainlogo}
          className="h-full w-10 object-cover"
          alt="main-logo"
        />
        {/* {bm2pay} */}
        {/* <img
          src={mainlogo}
          className="h-full w-20 object-cover"
          alt="main-logo"
        /> */}
        <div className="flex flex-col  font-poppins text-[20px] font-bold uppercase text-navy-700 dark:text-white">
          lightning
          <span className="text-sm font-light">checks</span>
        </div>
      </div>
      {/*lightning*/}
      <div className="mb-7 mt-[50px] h-px bg-gray-300 dark:bg-white/30" />
      {/* {bm2pay} */}
      {/* <div className="mb-7 mt-[38px] h-px bg-gray-300 dark:bg-white/30" /> */}
      {/* Nav item */}

      <ul className="mb-auto max-h-[75vh] overflow-auto pt-1 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
        <Links
          routes={updatedRoutes.filter((data) => data.layout === "/admin")}
        />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
