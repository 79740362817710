import authRequest from "../utils/authRequest";

export const createAPItoken = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/backoffice/create-token",
            method: "post",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllAPIAccessToken = (
    page: number,
    pageSize: number = 50,
    searchTexts?: any

): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/backoffice/get-all-token?page=${page}&per_page=${pageSize}&searchTexts=${searchTexts ? searchTexts : ""}`,
            method: "get",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const verifyBackOfficeAPI = (data: {
    password?: any;
    otp?: any;
    backoffice_access_token_id?: any
}): Promise<any> => {
    // console.log('id', id)	
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/backoffice/verify`,
            method: "post",
            data
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const deleteAPIAccessToken = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/backoffice/delete-token/" + id,
            method: "delete",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



export const updateAPITokenRecord = (data: any, id: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/backoffice/modify-token/" + id,
            method: "put",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const getAPIAccessTokenById = (
    token_id: string

): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/backoffice/get-token/${token_id}`,
            method: "get",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};



export const apiAccessHistory = (data: any, id: any): Promise<any> => {
    // console.log('id', id)	
    return new Promise((resolve, reject) => {
        authRequest({
            url: `api/v1/backoffice/get-api-call-history/${id}`,
            method: "post",
            data
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};