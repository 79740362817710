interface CurrencyDetails {
  title: string;
  icon: string;
  country_icon: string;
}

const currencyData: Record<string, CurrencyDetails> = {
  AED: {
    icon: "د.إ",
    title: "United Arab Emirates Dirham",
    country_icon: "AE",
    // country_icon: "🇦🇪"
  },
  AFN: {
    icon: "؋",
    title: "Afghan Afghani",
    country_icon: "AF",
    // country_icon: "🇦🇫"
  },
  ALL: {
    icon: "L",
    title: "Albanian Lek",
    country_icon: "AL",
    // country_icon: "🇦🇱"
  },
  AMD: {
    icon: "֏",
    title: "Armenian Dram",
    country_icon: "AM",
    // country_icon: "🇦🇲"
  },
  ANG: {
    icon: "ƒ",
    title: "Netherlands Antillean Guilder",
    country_icon: "AN",
    // country_icon: "🇳🇱"
  },
  AOA: {
    icon: "Kz",
    title: "Angolan Kwanza",
    country_icon: "AO",
    // country_icon: "🇦🇴"
  },
  ARS: {
    icon: "$",
    title: "Argentine Peso",
    country_icon: "AR",
    // country_icon: "🇦🇷"
  },
  AWG: {
    icon: "ƒ",
    title: "Aruban Florin",
    country_icon: "AW",
    // country_icon: "🇦🇼"
  },
  AZN: {
    icon: "₼",
    title: "Azerbaijani Manat",
    country_icon: "AZ",
    // country_icon: "🇦🇿"
  },
  BAM: {
    icon: "KM",
    title: "Bosnia-Herzegovina Convertible Mark",
    country_icon: "BA",
    // country_icon: "🇧🇦"
  },
  BBD: {
    icon: "$",
    title: "Barbadian Dollar",
    country_icon: "BB",
    // country_icon: "🇧🇧"
  },
  BDT: {
    icon: "৳",
    title: "Bangladeshi Taka",
    country_icon: "BD",
    // country_icon: "🇧🇩"
  },
  BGN: {
    icon: "лв",
    title: "Bulgarian Lev",
    country_icon: "BG",
    // country_icon: "🇧🇬"
  },
  BHD: {
    icon: ".د.ب",
    title: "Bahraini Dinar",
    country_icon: "BH",
    // country_icon: "🇧🇭"
  },
  BIF: {
    icon: "FBu",
    title: "Burundian Franc",
    country_icon: "BI",
    // country_icon: "🇧🇮"
  },
  BMD: {
    icon: "$",
    title: "Bermudan Dollar",
    country_icon: "BM",
    // country_icon: "🇧🇲"
  },
  BND: {
    icon: "$",
    title: "Brunei Dollar",
    country_icon: "BN",
    // country_icon: "🇧🇳"
  },
  BOB: {
    icon: "Bs",
    title: "Bolivian Boliviano",
    country_icon: "BO",
    // country_icon: "🇧🇴"
  },
  BRL: {
    icon: "R$",
    title: "Brazilian Real",
    country_icon: "BR",
    // country_icon: "🇧🇷"
  },
  BSD: {
    icon: "$",
    title: "Bahamian Dollar",
    country_icon: "BS",
    // country_icon: "🇧🇸"
  },
  BTN: {
    icon: "Nu",
    title: "Bhutanese Ngultrum",
    country_icon: "BT",
    // country_icon: "🇧🇹"
  },
  BWP: {
    icon: "P",
    title: "Botswanan Pula",
    country_icon: "BW",
    // country_icon: "🇧🇼"
  },
  BYN: {
    icon: "Br",
    title: "Belarusian Ruble",
    country_icon: "BY",
    // country_icon: "🇧🇾"
  },
  BZD: {
    icon: "BZ$",
    title: "Belize Dollar",
    country_icon: "BZ",
    // country_icon: "🇧🇿"
  },
  CDF: {
    icon: "FC",
    title: "Congolese Franc",
    country_icon: "CD",
    // country_icon: "🇨🇩"
  },
  CHF: {
    icon: "Fr",
    title: "Swiss Franc",
    country_icon: "CH",
    // country_icon: "🇨🇭"
  },
  CLP: {
    icon: "$",
    title: "Chilean Peso",
    country_icon: "CL",
    // country_icon: "🇨🇱"
  },
  CNY: {
    icon: "¥",
    title: "Chinese Yuan",
    country_icon: "CN",
    // country_icon: "🇨🇳"
  },
  COP: {
    icon: "$",
    title: "Colombian Peso",
    country_icon: "CO",
    // country_icon: "🇨🇴"
  },
  CRC: {
    icon: "₡",
    title: "Costa Rican Colón",
    country_icon: "CR",
    // country_icon: "🇨🇷"
  },
  CUC: {
    icon: "$",
    title: "Cuban Convertible Peso",
    country_icon: "CU",
    // country_icon: "🇨🇺"
  },
  CUP: {
    icon: "₱",
    title: "Cuban Peso",
    country_icon: "CU",
    // country_icon: "🇨🇺"
  },
  CVE: {
    icon: "$",
    title: "Cape Verdean Escudo",
    country_icon: "CV",
    // country_icon: "🇨🇻"
  },
  CZK: {
    icon: "Kč",
    title: "Czech Koruna",
    country_icon: "CZ",
    // country_icon: "🇨🇿"
  },
  DJF: {
    icon: "Fdj",
    title: "Djiboutian Franc",
    country_icon: "DJ",
    // country_icon: "🇩🇯"
  },
  DKK: {
    icon: "kr",
    title: "Danish Krone",
    country_icon: "DK",
    // country_icon: "🇩🇰"
  },
  DOP: {
    icon: "RD$",
    title: "Dominican Peso",
    country_icon: "DO",
    // country_icon: "🇩🇴"
  },
  DZD: {
    icon: "دج",
    title: "Algerian Dinar",
    country_icon: "DZ",
    // country_icon: "🇩🇿"
  },
  EGP: {
    icon: "£",
    title: "Egyptian Pound",
    country_icon: "EG",
    // country_icon: "🇪🇬"
  },
  ERN: {
    icon: "Nfk",
    title: "Eritrean Nakfa",
    country_icon: "ER",
    // country_icon: "🇪🇷"
  },
  ETB: {
    icon: "Br",
    title: "Ethiopian Birr",
    country_icon: "ET",
    // country_icon: "🇪🇹"
  },
  FJD: {
    icon: "$",
    title: "Fijian Dollar",
    country_icon: "FJ",
    // country_icon: "🇫🇯"
  },
  FKP: {
    icon: "£",
    title: "Falkland Islands Pound",
    country_icon: "FK",
    // country_icon: "🇫🇰"
  },
  GEL: {
    icon: "₾",
    title: "Georgian Lari",
    country_icon: "GE",
    // country_icon: "🇬🇪"
  },
  GGP: {
    icon: "£",
    title: "Guernsey Pound",
    country_icon: "GG",
    // country_icon: "🇬🇬"
  },
  GHS: {
    icon: "₵",
    title: "Ghanaian Cedi",
    country_icon: "GH",
    // country_icon: "🇬🇭"
  },
  GIP: {
    icon: "£",
    title: "Gibraltar Pound",
    country_icon: "GI",
    // country_icon: "🇬🇮"
  },
  GMD: {
    icon: "D",
    title: "Gambian Dalasi",
    country_icon: "GM",
    // country_icon: "🇬🇲"
  },
  GNF: {
    icon: "FG",
    title: "Guinean Franc",
    country_icon: "GN",
    // country_icon: "🇬🇳"
  },
  GTQ: {
    icon: "Q",
    title: "Guatemalan Quetzal",
    country_icon: "GT",
    // country_icon: "🇬🇹"
  },
  GYD: {
    icon: "$",
    title: "Guyanaese Dollar",
    country_icon: "GY",
    // country_icon: "🇬🇾"
  },
  HKD: {
    icon: "$",
    title: "Hong Kong Dollar",
    country_icon: "HK",
    // country_icon: "🇭🇰"
  },
  HNL: {
    icon: "L",
    title: "Honduran Lempira",
    country_icon: "HN",
    // country_icon: "🇭🇳"
  },
  HRK: {
    icon: "kn",
    title: "Croatian Kuna",
    country_icon: "HR",
    // country_icon: "🇭🇷"
  },
  HTG: {
    icon: "G",
    title: "Haitian Gourde",
    country_icon: "HT",
    // country_icon: "🇭🇹"
  },
  HUF: {
    icon: "Ft",
    title: "Hungarian Forint",
    country_icon: "HU",
    // country_icon: "🇭🇺"
  },
  IDR: {
    icon: "Rp",
    title: "Indonesian Rupiah",
    country_icon: "ID",
    // country_icon: "🇮🇩"
  },
  ILS: {
    icon: "₪",
    title: "Israeli New Shekel",
    country_icon: "IL",
    // country_icon: "🇮🇱"
  },
  IMP: {
    icon: "£",
    title: "Manx Pound",
    country_icon: "IM",
    // country_icon: "🇮🇲"
  },
  INR: {
    icon: "₹",
    title: "Indian Rupee",
    country_icon: "IN",
    // country_icon: "🇮🇳"
  },
  IQD: {
    icon: "ع.د",
    title: "Iraqi Dinar",
    country_icon: "IQ",
    // country_icon: "🇮🇶"
  },
  IRR: {
    icon: "﷼",
    title: "Iranian Rial",
    country_icon: "IR",
    // country_icon: "🇮🇷"
  },
  ISK: {
    icon: "kr",
    title: "Icelandic Króna",
    country_icon: "IS",
    // country_icon: "🇮🇸"
  },
  JEP: {
    icon: "£",
    title: "Jersey Pound",
    country_icon: "JE",
    // country_icon: "🇯🇪"
  },
  JMD: {
    icon: "J$",
    title: "Jamaican Dollar",
    country_icon: "JM",
    // country_icon: "🇯🇲"
  },
  JOD: {
    icon: "د.ا",
    title: "Jordanian Dinar",
    country_icon: "JO",
    // country_icon: "🇯🇴"
  },
  JPY: {
    icon: "¥",
    title: "Japanese Yen",
    country_icon: "JP",
    // country_icon: "🇯🇵"
  },
  KES: {
    icon: "KSh",
    title: "Kenyan Shilling",
    country_icon: "KE",
    // country_icon: "🇰🇪"
  },
  KGS: {
    icon: "с",
    title: "Kyrgyzstani Som",
    country_icon: "KG",
    // country_icon: "🇰🇬"
  },
  KHR: {
    icon: "៛",
    title: "Cambodian Riel",
    country_icon: "KH",
    // country_icon: "🇰🇭"
  },
  KMF: {
    icon: "CF",
    title: "Comorian Franc",
    country_icon: "KM",
    // country_icon: "🇰🇲"
  },
  KPW: {
    icon: "₩",
    title: "North Korean Won",
    country_icon: "KP",
    // country_icon: "🇰🇵"
  },
  KRW: {
    icon: "₩",
    title: "South Korean Won",
    country_icon: "KR",
    // country_icon: "🇰🇷"
  },
  KWD: {
    icon: "د.ك",
    title: "Kuwaiti Dinar",
    country_icon: "KW",
    // country_icon: "🇰🇼"
  },
  KYD: {
    icon: "$",
    title: "Cayman Islands Dollar",
    country_icon: "KY",
    // country_icon: "🇰🇾"
  },
  KZT: {
    icon: "₸",
    title: "Kazakhstani Tenge",
    country_icon: "KZ",
    // country_icon: "🇰🇿"
  },
  LAK: {
    icon: "₭",
    title: "Laotian Kip",
    country_icon: "LA",
    // country_icon: "🇱🇦"
  },
  LBP: {
    icon: "ل.ل",
    title: "Lebanese Pound",
    country_icon: "LB",
    // country_icon: "🇱🇧"
  },
  LKR: {
    icon: "Rs",
    title: "Sri Lankan Rupee",
    country_icon: "LK",
    // country_icon: "🇱🇰"
  },
  LRD: {
    icon: "$",
    title: "Liberian Dollar",
    country_icon: "LR",
    // country_icon: "🇱🇷"
  },
  LSL: {
    icon: "M",
    title: "Lesotho Loti",
    country_icon: "LS",
    // country_icon: "🇱🇸"
  },
  LYD: {
    icon: "ل.د",
    title: "Libyan Dinar",
    country_icon: "LY",
    // country_icon: "🇱🇾"
  },
  MAD: {
    icon: "د.م.",
    title: "Moroccan Dirham",
    country_icon: "MA",
    // country_icon: "🇲🇦"
  },
  MDL: {
    icon: "L",
    title: "Moldovan Leu",
    country_icon: "MD",
    // country_icon: "🇲🇩"
  },
  MGA: {
    icon: "Ar",
    title: "Malagasy Ariary",
    country_icon: "MG",
    // country_icon: "🇲🇬"
  },
  MKD: {
    icon: "ден",
    title: "Macedonian Denar",
    country_icon: "MK",
    // country_icon: "🇲🇰"
  },
  MMK: {
    icon: "K",
    title: "Burmese Kyat",
    country_icon: "MM",
    // country_icon: "🇲🇲"
  },
  MNT: {
    icon: "₮",
    title: "Mongolian Tögrög",
    country_icon: "MN",
    // country_icon: "🇲🇳"
  },
  MOP: {
    icon: "P",
    title: "Macanese Pataca",
    country_icon: "MO",
    // country_icon: "🇲🇴"
  },
  MRO: {
    icon: "UM",
    title: "Mauritanian Ouguiya",
    country_icon: "MR",
    // country_icon: "🇲🇷"
  },
  MRU: {
    icon: "UM",
    title: "Mauritanian Ouguiya",
    country_icon: "MR",
    // country_icon: "🇲🇷"
  },
  MUR: {
    icon: "₨",
    title: "Mauritian Rupee",
    country_icon: "MU",
    // country_icon: "🇲🇺"
  },
  MVR: {
    icon: "Rf",
    title: "Maldivian Rufiyaa",
    country_icon: "MV",
    // country_icon: "🇲🇻"
  },
  MWK: {
    icon: "MK",
    title: "Malawian Kwacha",
    country_icon: "MW",
    // country_icon: "🇲🇼"
  },
  MXN: {
    icon: "$",
    title: "Mexican Peso",
    country_icon: "MX",
    // country_icon: "🇲🇽"
  },
  MYR: {
    icon: "RM",
    title: "Malaysian Ringgit",
    country_icon: "MY",
    // country_icon: "🇲🇾"
  },
  MZN: {
    icon: "MT",
    title: "Mozambican Metical",
    country_icon: "MZ",
    // country_icon: "🇲🇿"
  },
  NAD: {
    icon: "$",
    title: "Namibian Dollar",
    country_icon: "NA",
    // country_icon: "🇳🇦"
  },
  NGN: {
    icon: "₦",
    title: "Nigerian Naira",
    country_icon: "NG",
    // country_icon: "🇳🇬"
  },
  NIO: {
    icon: "C$",
    title: "Nicaraguan Córdoba",
    country_icon: "NI",
    // country_icon: "🇳🇮"
  },
  NOK: {
    icon: "kr",
    title: "Norwegian Krone",
    country_icon: "NO",
    // country_icon: "🇳🇴"
  },
  NPR: {
    icon: "₨",
    title: "Nepalese Rupee",
    country_icon: "NP",
    // country_icon: "🇳🇵"
  },
  NZD: {
    icon: "$",
    title: "New Zealand Dollar",
    country_icon: "NZ",
    // country_icon: "🇳🇿"
  },
  OMR: {
    icon: "ر.ع.",
    title: "Omani Rial",
    country_icon: "OM",
    // country_icon: "🇴🇲"
  },
  PAB: {
    icon: "B/.",
    title: "Panamanian Balboa",
    country_icon: "PA",
    // country_icon: "🇵🇦"
  },
  PEN: {
    icon: "S/.",
    title: "Peruvian Sol",
    country_icon: "PE",
    // country_icon: "🇵🇪"
  },
  PGK: {
    icon: "K",
    title: "Papua New Guinean Kina",
    country_icon: "PG",
    // country_icon: "🇵🇬"
  },
  PHP: {
    icon: "₱",
    title: "Philippine Peso",
    country_icon: "PH",
    // country_icon: "🇵🇭"
  },
  PKR: {
    icon: "₨",
    title: "Pakistani Rupee",
    country_icon: "PK",
    // country_icon: "🇵🇰"
  },
  PLN: {
    icon: "zł",
    title: "Polish Złoty",
    country_icon: "PL",
    // country_icon: "🇵🇱"
  },
  PYG: {
    icon: "₲",
    title: "Paraguayan Guarani",
    country_icon: "PY",
    // country_icon: "🇵🇾"
  },
  QAR: {
    icon: "ر.ق",
    title: "Qatari Riyal",
    country_icon: "QA",
    // country_icon: "🇶🇦"
  },
  RON: {
    icon: "lei",
    title: "Romanian Leu",
    country_icon: "RO",
    // country_icon: "🇷🇴"
  },
  RSD: {
    icon: "дин",
    title: "Serbian Dinar",
    country_icon: "RS",
    // country_icon: "🇷🇸"
  },
  RUB: {
    icon: "₽",
    title: "Russian Ruble",
    country_icon: "RU",
    // country_icon: "🇷🇺"
  },
  RWF: {
    icon: "RF",
    title: "Rwandan Franc",
    country_icon: "RW",
    // country_icon: "🇷🇼"
  },
  SAR: {
    icon: "ر.س",
    title: "Saudi Riyal",
    country_icon: "SA",
    // country_icon: "🇸🇦"
  },
  SBD: {
    icon: "$",
    title: "Solomon Islands Dollar",
    country_icon: "SB",
    // country_icon: "🇸🇧"
  },
  SCR: {
    icon: "₨",
    title: "Seychellois Rupee",
    country_icon: "SC",
    // country_icon: "🇸🇨"
  },
  SDG: {
    icon: "ج.س.",
    title: "Sudanese Pound",
    country_icon: "SD",
    // country_icon: "🇸🇩"
  },
  SEK: {
    icon: "kr",
    title: "Swedish Krona",
    country_icon: "SE",
    // country_icon: "🇸🇪"
  },
  SGD: {
    icon: "$",
    title: "Singapore Dollar",
    country_icon: "SG",
    // country_icon: "🇸🇬"
  },
  SHP: {
    icon: "£",
    title: "Saint Helena Pound",
    country_icon: "SH",
    // country_icon: "🇸🇭"
  },
  SLL: {
    icon: "Le",
    title: "Sierra Leonean Leone",
    country_icon: "SL",
    // country_icon: "🇸🇱"
  },
  SOS: {
    icon: "S",
    title: "Somali Shilling",
    country_icon: "SO",
    // country_icon: "🇸🇴"
  },
  SRD: {
    icon: "$",
    title: "Surinamese Dollar",
    country_icon: "SR",
    // country_icon: "🇸🇷"
  },
  SSP: {
    icon: "£",
    title: "South Sudanese Pound",
    country_icon: "SS",
    // country_icon: "ss"
  },
  STN: {
    icon: "Db",
    title: "São Tomé and Príncipe Dobra",
    country_icon: "ST",
    // country_icon: "🇸🇹"
  },
  SVC: {
    icon: "$",
    title: "Salvadoran Colón",
    country_icon: "SV",
    // country_icon: "🇸🇻"
  },
  SYP: {
    icon: "£",
    title: "Syrian Pound",
    country_icon: "SY",
    // country_icon: "🇸🇾"
  },
  SZL: {
    icon: "L",
    title: "Swazi Lilangeni",
    country_icon: "SZ",
    // country_icon: "🇸🇿"
  },
  THB: {
    icon: "฿",
    title: "Thai Baht",
    country_icon: "TH",
    // country_icon: "🇹🇭"
  },
  TJS: {
    icon: "ЅМ",
    title: "Tajikistani Somoni",
    country_icon: "TJ",
    // country_icon: "🇹🇯"
  },
  TMT: {
    icon: "m",
    title: "Turkmenistan Manat",
    country_icon: "TM",
    // country_icon: "🇹🇲"
  },
  TND: {
    icon: "د.ت",
    title: "Tunisian Dinar",
    country_icon: "TN",
    // country_icon: "🇹🇳"
  },
  TOP: {
    icon: "T$",
    title: "Tongan Paʻanga",
    country_icon: "TO",
    // country_icon: "🇹🇴"
  },
  TRY: {
    icon: "₺",
    title: "Turkish Lira",
    country_icon: "TR",
    // country_icon: "🇹🇷"
  },
  TTD: {
    icon: "TT$",
    title: "Trinidad and Tobago Dollar",
    country_icon: "TT",
    // country_icon: "🇹🇹"
  },
  TWD: {
    icon: "NT$",
    title: "New Taiwan Dollar",
    country_icon: "TW",
    // country_icon: "🇹🇼"
  },
  TZS: {
    icon: "TSh",
    title: "Tanzanian Shilling",
    country_icon: "TZ",
    // country_icon: "🇹🇿"
  },
  UAH: {
    icon: "₴",
    title: "Ukrainian Hryvnia",
    country_icon: "UA",
    // country_icon: "🇺🇦"
  },
  UGX: {
    icon: "USh",
    title: "Ugandan Shilling",
    country_icon: "UG",
    // country_icon: "🇺🇬"
  },
  USD: {
    icon: "$",
    title: "United States Dollar",
    country_icon: "US",
    // country_icon: "🇺🇸"
  },
  USDT: {
    icon: "$",
    title: "USDT",
    country_icon: "USDT",
    // country_icon: "🇺🇸"
  },
  UYU: {
    icon: "$",
    title: "Uruguayan Peso",
    country_icon: "UY",
    // country_icon: "🇺🇾"
  },
  UZS: {
    icon: "so'm",
    title: "Uzbekistani Som",
    country_icon: "UZ",
    // country_icon: "🇺🇿"
  },
  VES: {
    icon: "Bs.",
    title: "Venezuelan Bolívar",
    country_icon: "VE",
    // country_icon: "🇻🇪"
  },
  VND: {
    icon: "₫",
    title: "Vietnamese Đồng",
    country_icon: "VN",
    // country_icon: "🇻🇳"
  },
  VUV: {
    icon: "VT",
    title: "Vanuatu Vatu",
    country_icon: "VU",
    // country_icon: "🇻🇺"
  },
  WST: {
    icon: "T",
    title: "Samoan Tala",
    country_icon: "WS",
    // country_icon: "🇼🇸"
  },
  XAF: {
    icon: "FCFA",
    title: "Central African CFA Franc",
    country_icon: "XA",
    // country_icon: "🌍"
  },
  XCD: {
    icon: "$",
    title: "East Caribbean Dollar",
    country_icon: "XC",
    // country_icon: "🌍"
  },
  XDR: {
    icon: "SDR",
    title: "Special Drawing Rights",
    country_icon: "XD",
    // country_icon: "🌍"
  },
  XOF: {
    icon: "CFA",
    title: "West African CFA franc",
    country_icon: "XO",
    // country_icon: "🌍"
  },
  XPF: {
    icon: "₣",
    title: "CFP Franc",
    country_icon: "XP",
    // country_icon: "🌍"
  },
  YER: {
    icon: "﷼",
    title: "Yemeni Rial",
    country_icon: "YE",
    // country_icon: "🇾🇪"
  },
  ZAR: {
    icon: "R",
    title: "South African Rand",
    country_icon: "ZA",
    // country_icon: "🇿🇦"
  },
  ZMW: {
    icon: "ZK",
    title: "Zambian Kwacha",
    country_icon: "ZM",
    // country_icon: "🇿🇲"
  },
  ZWL: {
    icon: "Z$",
    title: "Zimbabwean Dollar",
    country_icon: "ZW",
    // country_icon: "🇿🇼"
  },
  EUR: {
    icon: "€",
    title: "EURO",
    country_icon: "EU",
    // country_icon: "🇪🇺"
  },
  CAD: {
    icon: "$", // You can use the dollar sign or any other currency symbol
    title: "Canadian Dollar",
    country_icon: "CA", // Assuming you want to use the country code, you can also use an emoji like "🇨🇦"
  }
};


export const getCurrencySymbol = (currencyCode: string): CurrencyDetails | undefined => {
  return currencyData[currencyCode];
};

// Export an array of currency codes
export const currencyCodes: string[] = Object.keys(currencyData);