import { PaymentTable } from "./LogsTable/paymentTable";
import { Card } from "antd";
import { Select as AntSelect } from "antd";
import Searchbox from "components/fields/Searchbox";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import LogList from "./LogsTable/LogList";
import { getLogs } from "api/viewLogs";

export type PaymentRowObj = {
  id: string;
  user_id?: string;
  created_at?: string;
  all_data?: any;
  payment_id?:any;
  client_id?:any;
  organization_id?:string;
};



const TableOptions = [
  {
    label: "Payment",
    value: "payment",
  },
  {
    label: "Client",
    value: "client",
  },
  {
    label: "Organization",
    value: "organization",
  },
  {
    label: "Payout",
    value: "payout",
  },
];

const tableJsonData:any=[
  {
    "id": "20f99f37-d189-4507-aea6-043df7b3802b",
    "gateway_id": "17654add-e782-43bd-88c0-e90703cf35b3",
    "client_id": "rr7EvUBuErBwkh81GXrrsYlt1A02ApqItj3ZNL4XlyA",
    "payment_id": "eecb2033c27303be39d0fb2b31f5b50fb42b572cf223ec4409b683749614409d",
    "module_name": "payment",
    "payload": {
        "user_payload": {
            "first_name": "Jon",
            "middle_name": "Jay",
            "last_name": "Doe",
            "email": "khalife.joseph@gmail.com",
            "phone": "+15145570885",
            "city": "victoria",
            "address": "123 Main St.",
            "postal_code": "J7P0157",
            "state": "BC",
            "country": "CA",
            "ip": "49.36.81.225",
            "cardholder_name": "Joseph Khalife",
            "card_number": "4242424242424242",
            "expiration_month": "05",
            "expiration_year": "2026",
            "cvv": "154",
            "amount": "2589",
            "currency": "MXN",
            "identification_number": "19119119104",
            "identification_type": "CPF"
        },
        "gateway_payload": {
            "request": {
                "amount": 258900,
                "currency": "MXN",
                "return_url": "http://gateway-dev.bm2bank.com/payment/status/gateway_id/17654add-e782-43bd-88c0-e90703cf35b3/client_id/rr7EvUBuErBwkh81GXrrsYlt1A02ApqItj3ZNL4XlyA/payment_request_id/eecb2033c27303be39d0fb2b31f5b50fb42b572cf223ec4409b683749614409d",
                "description": "rr7EvUBuErBwkh81GXrrsYlt1A02ApqItj3ZNL4XlyA",
                "tracking_id": "yayVcxOdP3Ag",
                "expired_at": "2024-05-28T06:12:22-0400",
                "language": "en",
                "test": true,
                "billing_address": {
                    "first_name": "Jon",
                    "last_name": "Doe",
                    "country": "CA",
                    "city": "victoria",
                    "state": "BC",
                    "zip": "J7P0157",
                    "address": "123 Main St.",
                    "phone": "+15145570885"
                },
                "credit_card": {
                    "number": "4242424242424242",
                    "verification_value": "154",
                    "holder": "Joseph Khalife",
                    "exp_month": "05",
                    "exp_year": "2026"
                },
                "customer": {
                    "ip": "49.36.81.225",
                    "email": "khalife.joseph@gmail.com"
                }
            }
        }
    },
    "response": {
        "error": null,
        "api_response": {
            "id": "not_applicable",
            "amount": "2589",
            "status": "DECLINED",
            "currency": "MXN",
            "binInformation": {
                "lastFour": "4242",
                "bin": "424242",
                "card_type": "VISA"
            },
            "description": {
                "next_action": "not_applicable",
                "message": "Shop not found"
            },
            "error_code": "E.1004",
            "Descriptor": "Payment",
            "receipt_url": null,
            "qr_code": null,
            "request_date": "2024-05-28 09:57:23.645436",
            "payment_type": "CARD",
            "new_currency": null,
            "new_amount": "None"
        },
        "gateway_response": {
            "status": "error",
            "code": "E.1004",
            "message": "Shop not found",
            "friendly_message": "Shop not found"
        }
    },
    "api_status": "Successful",
    "payment_status": "DECLINED",
    "is_live": false,
    "created_at": "Tue, 28 May 2024 09:57:23 GMT"
}

]

export const ViewLogs = () => {
  const [selectedOptions, setSelectedOptions] = useState(
    TableOptions[0]?.value
  );
  const [selectedDateRange, setSelectedDateRang] = useState<any>();
  // const [searchVal,setSearchVal]=useState("")

  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchVal, setSearchVal] = useState<string>("");
  const [tableData,setTableData]=useState([])

  const handleValueChange = (e: any) => {
    const value = e.target.value;
    setSearchVal(value);
  };

  const fetchLogsData = () => {
    setIsLoading(true)

    // debugger
    getLogs(page, pageSize, searchVal, selectedOptions)
      .then((response: any) => {
        setTableData(response)



      })
      .catch((error) => {
        
      }).finally(()=>{
        setIsLoading(false)
      })
  };
  useEffect(() => {
    fetchLogsData();
  }, [selectedOptions,pageSize,page]);

  

  return (
    <>
      <div>
        <Card>
          <div className="grid grid-cols-3 gap-2">
            <div>
              <AntSelect
                allowClear={false}
                showSearch
                placeholder="Select Module"
                optionFilterProp="label"
                filterOption
                className="mb-3 h-12 w-full bg-gray-50 "
                options={TableOptions}
                value={selectedOptions || undefined}
                onChange={(event) => {
                  setSelectedOptions(event);
                }}
                notFoundContent={"No options found"}
              />
            </div>
            <div>
              <DatePicker
                className="w-full rounded border  p-3 outline-none"
                placeholderText="Select Date Range"
                startDate={
                  selectedDateRange?.starting_date &&
                  new Date(selectedDateRange?.starting_date)
                }
                endDate={
                  selectedDateRange?.ending_date &&
                  new Date(selectedDateRange?.ending_date)
                }
                selectsRange={true}
                isClearable={true}
                onChange={(dateRange) => {
                  const [a, b] = dateRange;
                  setSelectedDateRang((prev: any) => {
                    return {
                      ...prev,
                      starting_date: a,
                      ending_date: b,
                    };
                  });
                }}
              />
            </div>
            <div>
              <label
                htmlFor="search"
                className="sr-only mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
                  <svg
                    className="h-4 w-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="search"
                  className="block w-full rounded-lg border border-gray-300  p-4 py-3.5 ps-10 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="Search"
                  //   required
                  value={searchVal}
                  onChange={(e) => {
                    handleValueChange(e);
                  }}
                />
                {/* <button
                  type="submit"
                  className="absolute bottom-1.5 end-2.5 top-1.5 rounded-lg bg-blue-700 px-4 py-2 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Search
                </button> */}
              </div>
            </div>
          </div>
        </Card>
      </div>
      
      <div>
        <LogList
          pageSize={pageSize}
          setPageSize={setPageSize}
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          tableData={tableJsonData}
          selectedOptions={selectedOptions}
        />
      </div>
      <div>
        {/* {
          

          <PaymentTable
        tableData={tableData}
        isLoading={isLoading}
        />

        } */}
        </div>
    </>
  );
};
