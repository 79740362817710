
//Lightning Checks
// export const APIPath = "https://backend-dev.lightningchecks.com";  //DEV
export const APIPath = "https://backend.lightningchecks.com";    //PROD

//Bm2Pay
// export const APIPath = "https://backend-dev.bm2pay.com";  //DEV
// export const APIPath  = "https://backend.bm2pay.com";   //PROD

//Stagging bm2pay
// export const APIPath = "https://backend-stagging.bm2pay.com"; 


//local Testing url
// export const APIPath = "http://192.168.1.30:5252";  //LOCAL
