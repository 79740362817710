import { useEffect, useState, useContext, useCallback } from "react";
import toast from "react-hot-toast";
import PaymentTable from "./components/BlackList";
import { getAllPayments, getPaymentsFilter, getCardType, getSearchPayments } from "api/payments";
import { getAllClients } from "api/clients";
import { ClientContext } from "ClientProvider";
import { getSingleRole } from "api/role";
import { getAllGateways } from "api/gateways";
import { getId } from "utils/auth";
import { getImage } from "utils/commonFunction";
import { getAllPayouts } from "api/payouts";
import BlackList from "./components/BlackList";
import CreateBlockClientsModal from "./components/CreateBlockeclientModal";
import { getAllBlackList } from "api/blackListClient";

export type BlackListClientsRowObj = {
  id: string;
  created_at: string;
  first_name:string;
  last_name:string;
  email?:string;
  is_blacklisted?:boolean;
  bin:string;
  last_four:string;
  attempts?:string
};

const BlackListClients = () => {
  const [blackListClients, setBlackListClients] = useState<BlackListClientsRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTexts, setSearchTexts] = useState<string>("");

  const { paymentType, singleRoleData, setSingleRoleData, filter, setFilter } =
    useContext(ClientContext);
 

  const fetchRole = () => {
    // setIsLoading(true);
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
          "Something went wrong while fetching role"
        );
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  useEffect(() => {
    // fetchBlackListClientsData()
    fetchRole()
  }, []);

  const fetchBlackListClientsData = () => {
    setIsLoading(true);
    getAllBlackList(
      page,
      pageSize,
      searchTexts
    
    )
      .then((data) => {
        setBlackListClients(data[0])
        setTotalPage(data[1]?.total_pages);
        setTotalItems(data[1]?.total_items);
        setCurrentPage(data[1]?.current_page);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
          "Something went wrong while fetching data!"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    fetchBlackListClientsData()
  }, [page, pageSize, filter,searchTexts]);
 
  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";

    if (search_txt !== searchTexts && searchTexts !== "") {
        await setSearchTexts(search_txt);
        setPage(1);
        setCurrentPage(1);
    } else {
        await setSearchTexts(search_txt);
    }

}

  return (

    <>
    <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
       
       { 
       singleRoleData?.[0]?.blacklist?.value?.add_blacklist&&
       <CreateBlockClientsModal
          fetchBlackListClientsData={fetchBlackListClientsData}
          roleData={singleRoleData}
        />}
      </div>
      <div className="mt-5 pt-5">
        <BlackList
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          roleData={singleRoleData}
          tableData={blackListClients}
          fetchBlackListClientsData={fetchBlackListClientsData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onValueChange={serchboxValueChange}


        />
      </div>
    </>
  );
};

export default BlackListClients;
