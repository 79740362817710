import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/popover'
import { Portal } from '@chakra-ui/portal'
import React from 'react'

function CustomToolTip(props:any) {
  return (
    <>
     <Popover
                  
                  >
                    <PopoverTrigger  >
                      <button className="flex items-center gap-1.5">
                        {props?.trigger}

                      </button>
                    </PopoverTrigger>
                    <Portal >
                      <PopoverContent borderColor='blue.800' className="z-20 dark:bg-gray-800 shadow  invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 p-3 border-6 shadow !bg-gray-700">
                        <PopoverHeader>
                          <span className="text-sm  font-bold text-white">
                        {props?.header}
                        </span>
                        </PopoverHeader>
                        <PopoverArrow  className="border h-8 w-8 border-gray-700 !bg-gray-700  "  />
                        <PopoverBody className="overflow-auto max-h-[50vh] text-white">
                        {props?.content}


                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
    </>
  )
}

export default CustomToolTip