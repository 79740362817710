import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useState } from "react";
import { SettlementRowObj } from "..";
import { MdCancel, MdCheckCircle, MdCheckCircleOutline, MdDelete } from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Searchbox from "components/fields/Searchbox";
import { getImage } from "utils/commonFunction";
import { BsDownload, BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import Pagination from "components/pagination";
import toast from "react-hot-toast";
import { deleteSettlementReport } from "api/settlement";
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from "@chakra-ui/hooks";
import CrerateSettlementReport from "./CrerateSettlementReport";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import StatusRender from "views/admin/payouts/components/StatusRender";
import { getProfile } from "api/users";
import { IoEye } from "react-icons/io5";
import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";
import { deleteAPIAccessToken, verifyBackOfficeAPI } from "api/api_access";
import CrerateAPIAccessFilter from "./CrerateAPIAccessFilter";


function SettlementTable(props: {
  tableData: any;
  fetchAllsettlement: () => void;
  isLoading: boolean;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  roleData: any;
  timeZone: any;
  allCardType: any;
  onValueChange: (value: string) => void;
  allOrgDetails: any
}) {
  const {
    tableData,
    fetchAllsettlement,
    page,
    setPage,
    currentPage,
    totalpage,
    totalItems,
    pageSize,
    setPageSize,
    roleData,
    timeZone,
    allCardType,
    onValueChange,
    allOrgDetails
  } = props;
  let defaultData = tableData;
  let showPaymentName = roleData[0]?.payment_method?.value?.show_payment_method_name;
  //console.log("allCardType3333",allCardType);
  console.log("tableData", tableData);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenDelete, setIsopenDelete] = useState(false)
  const [DeleteRowId, setDeleteRowId] = useState("")

  const [isOpenTokenIdModal, setIsOpenTokenIdModal] = useState(false);
  const [userVerificationData, setUserVerificationData] = useState<any>({})

  const deleteSettlementReportFun = (id: any) => {
    // let id:any={
    //     id:id
    // }
    deleteAPIAccessToken(DeleteRowId).then((res) => {
      toast.success("Record deleted successfully!")
      fetchAllsettlement()
      handleClose()


    }).catch((error) => {
      toast.error("Something went wrong!")
    })
  }
  const columns = [
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {console.log("info", info)} */}
          {info.row.original?.name}

          {/* {"Test Org"} */}
        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Created Date
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {"Test Date"} */}
          {info.row.original?.created_at}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Private Token

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {info.row.original?.token?.substring(0, 10)} */}
          {
            info?.row?.original?.token ?
              <ShortTruncateCopy info={info.row.original?.token} showCopy={true} />
              :
              <>
                <span>
                  {"**********"}
                </span>
                <IoEye
                  className=' h-5 w-5  ml-2 cursor-pointer'
                  onClick={() => {

                    getProfile().then((data) => {
                      let is_auth_2fa_activate = data[0]?.auth_2fa

                      setIsOpen2FAPopup(true)


                      setUserVerificationData((prev: any) => {
                        return {
                          ...prev,
                          backoffice_access_token_id: info?.row?.original?.id,
                          is_auth_2fa_activate
                        }
                      })

                    }).catch((error) => {
                      toast.error("something went wrong! please try after some time.")

                    }).finally(() => {

                    })

                  }}
                />

              </>
          }



        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Expiry

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.expiry_date || "-"}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Daily API Limit

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.api_hit_limit || "-"}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Status
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {info.row.original?.status || "-"} */}



          {info.row.original?.status == "ACTIVE" ? <p className="text-sm w-fit px-2 py-1 font-bold bg-teal-100 dark:bg-teal-50 rounded-lg text-teal-700 dark:text-white">
            <span className="text-teal-500 text-center uppercase">{info.row.original?.status}</span>
          </p> : info.row.original?.status == "BLOCK" ?
            <p className="text-sm w-fit px-2 py-1 font-bold bg-red-100 dark:bg-red-50 rounded-lg text-red-700 dark:text-white">
              <span className="text-red-500 text-center uppercase">{info.row.original?.status}</span>
            </p>
            : "-"
          }

        </div>
      ),
    }),

    columnHelper.accessor("name", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          {
            roleData?.[0]?.api?.status == "none" ? "" : "ACTION"
          }
        </p>
      ),
      cell: (info: any) => (
        <p className="flex items-center gap-3 text-lg font-bold">
          {roleData?.[0]?.api?.value?.view_api && <CrerateSettlementReport fetchAllsettlement={fetchAllsettlement} allOrgDetails={allOrgDetails} roleData={roleData} isInfo={true} info={info} />

          }
          {roleData?.[0]?.api?.value?.delete_api && <MdDelete onClick={() => {
            // deleteSettlementReportFun(info.row.original?.id)
            setIsopenDelete(true)
            setDeleteRowId(info.row.original?.id)

          }} className="text-red-500 font-bold cursor-pointer" />}
          {
            roleData?.[0]?.api?.value?.edit_api && <CrerateSettlementReport fetchAllsettlement={fetchAllsettlement} allOrgDetails={allOrgDetails} roleData={roleData} isInfo={false} info={info} />
          }

          {
            roleData?.[0]?.api?.value?.view_history_api &&
            <CrerateAPIAccessFilter fetchAllsettlement={fetchAllsettlement} roleData={roleData} info={info} />
          }


        </p>
      ),
    }),
  ]; // eslint-disable-next-line

  const columns2 = [
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {console.log("info", info)} */}
          {info.row.original?.name}

          {/* {"Test Org"} */}
        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Created Date
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {"Test Date"} */}
          {info.row.original?.created_at}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Private Token

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {info.row.original?.token?.substring(0, 10)} */}
          {
            info?.row?.original?.token ?
              <ShortTruncateCopy info={info.row.original?.token} showCopy={true} />
              :
              <>
                <span>
                  {"**********"}
                </span>
                <IoEye
                  className=' h-5 w-5  ml-2 cursor-pointer'
                  onClick={() => {

                    getProfile().then((data) => {
                      let is_auth_2fa_activate = data[0]?.auth_2fa

                      setIsOpen2FAPopup(true)


                      setUserVerificationData((prev: any) => {
                        return {
                          ...prev,
                          backoffice_access_token_id: info?.row?.original?.id,
                          is_auth_2fa_activate
                        }
                      })

                    }).catch((error) => {
                      toast.error("something went wrong! please try after some time.")

                    }).finally(() => {

                    })

                  }}
                />

              </>
          }



        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Expiry

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.expiry_date || "-"}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Daily API Limit

        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.api_hit_limit || "-"}

        </div>
      ),
    }),

    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Status
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {/* {info.row.original?.status || "-"} */}



          {info.row.original?.status == "ACTIVE" ? <p className="text-sm w-fit px-2 py-1 font-bold bg-teal-100 dark:bg-teal-50 rounded-lg text-teal-700 dark:text-white">
            <span className="text-teal-500 text-center uppercase">{info.row.original?.status}</span>
          </p> : info.row.original?.status == "BLOCK" ?
            <p className="text-sm w-fit px-2 py-1 font-bold bg-red-100 dark:bg-red-50 rounded-lg text-red-700 dark:text-white">
              <span className="text-red-500 text-center uppercase">{info.row.original?.status}</span>
            </p>
            : "-"
          }

        </div>
      ),
    }),

   
  ];


  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchVal, setSearchVal] = React.useState<any>("");
  const [data, setData] = React.useState(() => [...defaultData]);
  const [isOpen2FAPopup, setIsOpen2FAPopup] = useState<boolean>(false)

  const onClose2FAPopup = () => {
    setIsOpen2FAPopup(false)
    setUserVerificationData({})
  }

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns:
     (roleData?.[0]?.api?.value?.view_api||roleData?.[0]?.api?.value?.delete_api ||roleData?.[0]?.api?.value?.edit_api|| roleData?.[0]?.api?.value?.view_history_api)? columns:columns2,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleValueChange = (e: any) => {
    onValueChange(e);
    setSearchVal(e);
  };

  console.log("setData", data)

  const handleClose = () => {
    setIsopenDelete(false)
    setDeleteRowId("")

  }
  const verify2FACreateAccessToken = () => {
    let payload = {
      "password": userVerificationData?.password,
      "otp": userVerificationData?.passCode,
      "backoffice_access_token_id": userVerificationData?.backoffice_access_token_id
    }
    verifyBackOfficeAPI(payload).then((res) => {
      let ResData = res

      setData((prev: any) => {
        return prev?.map((tData: any) => {
          if (tData?.id == ResData?.id) {
            return {
              ...tData,
              "token": ResData?.token,
              "is_verified": ResData?.is_verified

            }
          } else {
            return tData
          }
        })

      })

      toast.success(res?.message)
      onClose2FAPopup()



    }).catch((error) => {
      toast.error("Something went wrong!")


    })
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 py-4"}>
      <header className="relative flex items-center justify-between pt-5 flex-wrap">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          API Tokens
        </div>
        <Searchbox onSearch={handleValueChange} />
      </header>

      <div className="mt-4 overflow-x-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 relative overflow-x-auto shadow-md sm:rounded-lg">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr
                  key={headerGroup.id}
                // className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {table.getRowModel().rows?.length > 0 ? table.getRowModel().rows.map((row: any) => {
                return (
                  <tr key={row.id} className="p-2 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    {row.getVisibleCells().map((cell: any) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4 p-2"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
                :
                <tr>
                  <td colSpan={10} >
                    <p className="text-center p-4" >No records found.</p>
                  </td>

                </tr>
              }
            </tbody>
          </table>
        )}
      </div>

      <Modal isOpen={isOpenDelete} onClose={() => { }}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="top-[12vh] !z-[1002] !m-auto sm:my-8 sm:w-full sm:max-w-lg lg:max-w-3xl md:top-[11vh]"> */}
        <ModalContent className="z-[1002] !m-auto !p-3 sm:my-8 sm:w-full max-w-[800px] sm:max-w-xxl flex justify-center min-h-[100vh]">
          <ModalBody>

            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {"Confirm"}
              </h1>
              <p className="p-5 px-[30px] text-lg font-bold" >{"Do you want to delete this record?"}</p>

              <div className="mt-5 flex justify-end gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-indigo-50  px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                {true && <button
                  onClick={deleteSettlementReportFun}
                  //   disabled={viewMode}
                  className="linear rounded-xl bg-red-100  px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >

                  {"Delete"}

                </button>}

              </div>

            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen2FAPopup} onClose={onClose2FAPopup}>
        <ModalOverlay className="bg-[#000]   !opacity-30 z-20" />
        <ModalContent className="!z-[1002] !m-auto   shadow !w-max min-w-[350px] !max-w-[85%] md:top-[12vh] ">

          <ModalBody className="">
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[450px] flex flex-col !z-[1004] ">
              {!userVerificationData?.is_auth_2fa_activate ? <>
                <h1 className=" text-2xl font-bold">
                  {"2FA Verification"}
                </h1>
                <p className="opacity-50">To see Token please verify 2FA code.</p>
                <br />

                <p className="">Your account does not meet the necessary requirements in order to see Token, please visit <Link to="/admin/profile">profile settings</Link> and activate 2FA code.</p>
                <div className="flex gap-2  mt-3 justify-end">
                  <button
                    onClick={onClose2FAPopup}
                    className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Close
                  </button>{" "}

                </div>
              </> :

                <>
                  <h1 className=" text-2xl font-bold">
                    {"2FA Verification"}
                  </h1>
                  <p className="opacity-50">To see Token please verify 2FA code.</p>

                  <div className="w-72 bg-white">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={userVerificationData?.password}
                      label="Password *"
                      placeholder="Enter password"
                      id="password"
                      type={`${userVerificationData?.isShow ? "text" : "password"}`}
                      onChange={(e) => {
                        const name = e.target.id
                        const value = e.target?.value
                        setUserVerificationData((prev: any) => {
                          return {
                            ...prev,
                            [name]: value
                          }
                        })
                      }}

                      passwordShow={userVerificationData?.isShow}
                      setPasswordShow={() => setUserVerificationData((prev: any) => {
                        return {
                          ...prev,
                          isShow: !prev?.isShow
                        }
                      })}

                    />

                  </div>
                  <div className="w-72">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={userVerificationData?.passCode}

                      label="2FA Code *"
                      placeholder="Enter 2FA 6 digit code"
                      id="passCode"
                      type="number"
                      onChange={(e) => {
                        const name = e.target.id
                        const value = e.target?.value?.trim()
                        if (value?.length <= 6) {
                          setUserVerificationData((prev: any) => {
                            return {
                              ...prev,
                              [name]: value
                            }
                          })
                        }

                      }}


                    />
                  </div>
                  <div className="flex gap-2  mt-3 justify-end">
                    <button
                      onClick={onClose2FAPopup}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"

                    >
                      Close
                    </button>{" "}
                    <button
                      className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      onClick={() => {
                        verify2FACreateAccessToken()
                      }}

                    >
                      Submit
                    </button>
                  </div>
                </>}
            </Card>
          </ModalBody>

        </ModalContent>
      </Modal>



      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}

      />
    </Card>
  );
}

export default SettlementTable;
const columnHelper = createColumnHelper<SettlementRowObj>();
