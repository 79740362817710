import React, { useEffect, useState } from 'react'
import SmartRouting from '.'
import toast from 'react-hot-toast'
import { MdDelete, MdFileCopy } from 'react-icons/md'
import TextArea from 'components/fields/TextArea'
import { BsPlusCircle } from 'react-icons/bs'
import { v4 as uuidv4 } from "uuid";
import { createSmartRouting, getSmartRouting } from 'api/smart-routing'
import { useParams } from 'react-router-dom'
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import Card from "components/card";
import InfoModal from './InfoModal'
import DivLoader from 'components/divloader/DivLoader'

let apiData = [
    {
        "id": "476c9afc-cf51-435a-905a-305d0c08bc81",
        "operational_operator": "AND",
        "conditions": [
            {
                "param1": "geo-location",
                "param2": "AL",
                "condition_type": "EQUALS"
            },
            {
                "param1": "geo-location",
                "param2": "AG",
                "condition_type": "EQUALS"
            }
        ],
        "child_smart_condition": {
            "id": "d8ae32c6-6ad4-484b-8ed3-22769c1be2f0",
            "operational_operator": "SOLO",
            "conditions": [
                {
                    "param1": "geo-location",
                    "param2": "AZ",
                    "condition_type": "EQUALS"
                }
            ],
            "child_smart_condition": {
                "id": "d00fddee-75c4-46d5-ab7d-c7a961d0f8fa",
                "operational_operator": "SOLO",
                "conditions": [
                    {
                        "param1": "geo-location",
                        "param2": "GH",
                        "condition_type": "EQUALS"
                    }
                ],
                "child_smart_condition": {
                    "id": "2e0cbd7a-079d-4a39-8227-d8841a251655",
                    "operational_operator": "SOLO",
                    "conditions": [
                        {
                            "param1": "geo-location",
                            "param2": "PT",
                            "condition_type": "EQUALS"
                        }
                    ],
                    "action": {
                        "name": "process_payment",
                        "arguments": {
                            "name": "gateway",
                            "value": "AUREA VIA"
                        }
                    }
                }
            }
        }
    },
    {
        "id": "cb0ccd84-41a1-4698-89b5-7b9124b3c545",
        "operational_operator": "SOLO",
        "conditions": [
            {
                "param1": "geo-location",
                "param2": "GU",
                "condition_type": "EQUALS"
            }
        ],
        "child_smart_condition": {
            "id": "cf175329-3787-4bbd-8269-5b6f8c21a937",
            "operational_operator": "SOLO",
            "conditions": [
                {
                    "param1": "geo-location",
                    "param2": "EG",
                    "condition_type": "EQUALS"
                }
            ],
            "action": {
                "name": "process_payment",
                "arguments": {
                    "name": "gateway",
                    "value": "AUREA VIA"
                }
            }
        }
    }
]

let internalFormat = {
    "0": {},
    "1": {
        "0": {},
        "1": {
            "type": "ACTION",
            "action": {
                "type": "FUNCTION",
                "name": "process_payment",
                "arguments": [
                    {
                        "name": "gateway",
                        "type": "STATIC",
                        "value": "AUREA VIA"
                    }
                ]
            },
            "id": "5d9bd35e-8881-4cee-8a96-21b60110d71e"
        },
        "type": "CONDITION",
        "condition_type": "SOLO",
        "conditions": {
            "type": "EQUALS",
            "left": {
                "type": "VARIABLE",
                "name": "geo-location"
            },
            "right": {
                "type": "STATIC",
                "value": "EG"
            }
        },
        "id": "cf175329-3787-4bbd-8269-5b6f8c21a937"
    },
    "type": "CONDITION",
    "condition_type": "SOLO",
    "conditions": {
        "type": "EQUALS",
        "left": {
            "type": "VARIABLE",
            "name": "geo-location"
        },
        "right": {
            "type": "STATIC",
            "value": "GU"
        }
    },
    "id": "cb0ccd84-41a1-4698-89b5-7b9124b3c545"
}

function SmartRoutings() {
    // debugger
    const [routingData, setRoutingData] = useState([])
    const [routingDataForJSON, setRoutingDataForJSON] = useState([])
    const [routingArray, setRoutingArray] = useState([])

    const [routingList, setRoutingList] = useState<any>([])
    const [deleteRouteRow, setDeleteRouteRow] = useState<null|number>(null)
    const [OpenDeleteRowModal, setOpenDeleteRowModal] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [viewMode, setViewMode] = useState("FLOW")
    const prams = useParams()
    const { id: clientId } = prams
    const getFomatObject = (data: any) => {
        let smart_condition: any = {
            id: data?.id,
            operational_operator: data?.condition_type,
            conditions:
                Array.isArray(data?.conditions) ?
                    (data?.conditions?.length > 0
                        ? data?.conditions?.map((mData: any) => {
                            return {
                                param1: mData?.left?.name,
                                param2: mData?.right?.value,
                                condition_type: mData?.type

                            }

                        }) : [])
                    :
                    [data?.conditions]?.map((mData: any) => {
                        return {
                            param1: mData?.left?.name,
                            param2: mData?.right?.value,
                            condition_type: mData?.type

                        }

                    }),

            action: data[1]?.type == "ACTION" ? {
                name: "process_payment",
                arguments: {
                    name: "gateway",
                    value: data[1]?.type == "ACTION" ? data[1]?.action?.arguments[0]?.value : ""

                }
            } : undefined,
            child_smart_condition:
                data[1]?.type == "CONDITION" ? getFomatObject(data[1]) : undefined

        }
        return smart_condition

    }
    const getRoutignData = (data: any) => {
        // const filterObject = getFomatObject(data)
        // for (const key in filterObject) {
        //     if (filterObject[key] == undefined) {
        //         delete filterObject[key]
        //     }
        // }
        // console.log("smart_condition", getFomatObject(data))
        // console.log("smart_condition_data", data)
        // if (Object.keys(data)?.length > 0) {
        //     let a: any = []
        //     setRoutingData((prev: any) => {
        //         if (prev.length > 0) {
        //             if (prev?.filter((f: any) => f?.id == data?.id)?.length > 0) {
        //                 return prev?.map((m: any) => {
        //                     if (m?.id == data?.id) {
        //                         return filterObject
        //                     } else {
        //                         return m
        //                     }
        //                 })

        //             } else {
        //                 prev?.forEach((D: any) => {
        //                     a.push(D)
        //                 })
        //                 a.push(filterObject)
        //                 return a
        //             }
        //         } else {
        //             return [filterObject]
        //         }
        //     })
        // }
        let a: any = []
        if (Object.keys(data)?.length > 0){
            setRoutingData((prev: any) => {
                if (prev.length > 0) {
                    if (prev?.filter((f: any) => f?.id == data?.id)?.length > 0) {
                        return prev?.map((m: any) => {
                            if (m?.id == data?.id) {
                                return data
                            } else {
                                return m
                            }
                        })
    
                    } else {
                        prev?.forEach((D: any) => {
                            a.push(D)
                        })
                        a.push(data)
                        return a
                    }
                } else {
                    return [data]
                }
            })
        }
       
    }
    // console.log("routingList", routingList)
    // console.log("routingData", routingData)
    const crrateSmartRouting = () => {
        // setRoutingList((prev: any) => {
        //     return {
        //         ...prev,
        //         [uuidv4()]: <SmartRouting getRoutignData={getRoutignData} />
        //     }
        // })

        setRoutingList((prev: any) => {
            return [
                ...prev,
                {
                    id:uuidv4(),
                    component:   <SmartRouting getRoutignData={getRoutignData} />
            }
            ]




        })
    }

    // const removeRoutingComponent = (cKey: any) => {
    //     let temOj = routingList
    //     delete temOj[cKey]
    //     setRoutingList(temOj)

    // }

    const saveChanges = async () => {
        try {
                    setLoading(true);

            if (true) {
                // POST API
                if (false) {
                    // setLoading(true);
                    // await updateSmartRouting(routing, id).then(async (res) => {
                    //   if (res?.client_id) {
                    //     await getSmartRouting(res?.client_id)
                    //       .then((data: any) => {
                    //         // console.log("data1111", data);

                    //         setFetchData(data?.route_info?.conditions?.right?.value);
                    //         setRouting(data?.route_info);
                    //         setRoutingE(JSON.stringify(data?.route_info, null, 4));
                    //         setLoading(false);
                    //         if (data?.route_info) {
                    //           setEditData(true);
                    //         }
                    //       })
                    //       .catch((err: any) => {
                    //         setLoading(false);
                    //         toast.error(
                    //           err?.response?.data?.message ?? "Unable to fetch data"
                    //         );
                    //       });
                    //   } else {
                    //     setLoading(false);
                    //   }
                    // });
                } else {
                    await createSmartRouting({
                        smart_routing_conditions: routingData
                    }, clientId);
                    fetchRoutingData()
                }

                // Display a success toast if the API call is successful.
                toast.success("Saved");
                // setLoading(false);
            }

        } catch (error) {
            toast.error("Invalid JSON Data");
        }finally{
            setLoading(false);

        }
    }

    const getType = (objData: any) => {
        if ("child_smart_condition" in objData) {
            return true
        } else if ("action" in objData) {
            return false

        }

    }
    const getConditions = (conditionsData: any) => {
        let formatData = conditionsData?.length > 1 ?
            conditionsData?.map((mData: any) => {
                return {
                    "type": mData?.condition_type,
                    "left": {
                        "type": "VARIABLE",
                        "name": mData?.param1
                    },
                    "right": {
                        "type": "STATIC",
                        "value": mData?.param2
                    }
                }
            })
            : conditionsData?.map((mData: any) => {
                return {
                    "type": mData?.condition_type,
                    "left": {
                        "type": "VARIABLE",
                        "name": mData?.param1
                    },
                    "right": {
                        "type": "STATIC",
                        "value": mData?.param2
                    }
                }
            })[0]
        return formatData

    }
    const getFomatAPIObject = (data: any) => {
        let formatdata: any = {
            "0": {},
            id: data?.id,
            type: getType(data) ? "CONDITION" : "ACTION",
            condition_type: data?.operational_operator,
            conditions: getConditions(data?.conditions),
            "1": getType(data) ? getFomatAPIObject(data?.child_smart_condition) :
                {
                    type: "ACTION",
                    id: uuidv4(),
                    "action": {
                        "type": "FUNCTION",
                        "name": data?.action?.name,
                        "arguments": [
                            {
                                "name": data?.action?.arguments?.name,
                                "type": "STATIC",
                                "value": data?.action?.arguments?.value
                            }
                        ]
                    }

                }
        }
        return formatdata

    }
    // console.log("formatAPIdata",apiData.map((mData:any)=>{
    //     return getFomatAPIObject(mData)
    // }) )

    useEffect(() => {
        fetchRoutingData()
    }, [])
    const fetchRoutingData = () => {
        // calling GET API
        setLoading(true)

        getSmartRouting(clientId)
            .then((data: any) => {
                // setFetchData(data?.route_info?.conditions?.right?.value);
                // setRoutingArray(data?.route_info?.smart_routing_conditions);

                // console.log("getFomatAPIObject(mData)",getFomatAPIObject(data?.route_info?.smart_routing_conditions[0]))


                // setRoutingE(JSON.stringify(data?.route_info, null, 4));
                if (data?.route_info?.smart_routing_conditions) {
                    //   setEditData(true);
                    setRoutingList((prev: any) => {
                        return data?.route_info?.smart_routing_conditions?.map((mData: any) => {
                            return {
                                id:uuidv4(),
                               component :<SmartRouting initialRoutesValues={mData} getRoutignData={getRoutignData} />
                            }
                        })
                    })
                }
            })
            .catch((err: any) => {
                // toast(err?.response?.data?.message ?? "Unable to fetch data", {
                //   icon: (
                //     <RiErrorWarningLine
                //       className="h-6 w-6"
                //       style={{ color: "#ca8a04" }}
                //     />
                //   ),
                // });
            }).finally(()=>{
                setLoading(false)
            })

    }

    console.log("routingListroutingList", routingList)
    console.log("routingData", routingData)

    return (
        <div className='py-5   p-3 pt-1'>
            {
                    isLoading&&      <DivLoader className="m-5 h-6 w-6 border-indigo-500" />

            }
            {true && <div className="my-5 flex justify-between gap-3">
                <div className='flex gap-2'>
                    <button
                        className={`w-fit rounded-lg border px-4 py-2 outline-none ${viewMode === "FLOW"
                            ? "border-indigo-300 bg-indigo-50 text-indigo-500"
                            : "border-gray-200 bg-white"
                            }`}
                        onClick={() => {
                            setViewMode("FLOW");
                            // setRoutingData(routingDataForJSON)

                        }}
                    >
                        FLOW View
                    </button>
                    {/* <button
                        className={`w-fit rounded-lg border px-4 py-2 outline-none ${viewMode === "JSON"
                            ? "border-indigo-300 bg-indigo-50 text-indigo-500"
                            : "border-gray-200 bg-white"
                            }`}
                        onClick={() => {
                            setViewMode("JSON");
                            setRoutingDataForJSON(routingData)
                            setRoutingData([])
                        }}
                    >
                        JSON View
                    </button> */}
                <InfoModal heading={"Smart Routes JSON DATA"} info={routingData} />
                    
                </div>
                <div className={` `}>
                    <button
                        className={`w-fit flex rounded-xl border border-teal-300 bg-teal-50 px-5 py-2 text-teal-500 outline-none`}
                        onClick={saveChanges}
                        disabled={isLoading}
                    >
<span className='flex'>
{isLoading&& <span> <DivLoader className="h-3 w-3 m-0 border-indigo-500" /></span> } <span className='mx-2'>
               Save Changes
                </span> 
</span>
               
                    </button>
                </div>
            </div>}
            <div>
                <hr />
            </div>
            {
                viewMode == "JSON" ? <div className="relative">
                    <TextArea
                        variant="auth"
                        extra="mb-2 w-full"
                        label=""
                        placeholder=""
                        id={"routing"}
                        cols={80}
                        height="700px"
                        maxHeight="1000px"
                        value={JSON.stringify(routingDataForJSON, null, 4)}
                    //   onChange={handleInputChange}
                    // state={hasError ? "error" : ""}
                    />
                    <div className="absolute right-2 top-3 flex items-center gap-4">
                        <MdFileCopy
                            className="h-6 w-6 cursor-pointer text-teal-500"
                            onClick={async () => {
                                await navigator.clipboard.writeText(JSON.stringify(routingData));
                                // console.log('routingE', routingE)
                                if (routingData) {
                                    toast.success("Copied");
                                }
                                // if(routing){
                                //   toast.success("Copied ");
                                // }
                            }}
                        />
                    </div>
                </div>
                    :
                    false && <div className=''>
                        {
                            Object.keys(routingList)?.map((key: any) => {
                                return <div className='  my-3 '>
                                    <div className=''>
                                        {
                                            routingList[key]
                                        }
                                    </div>
                                    {/* <div className='my-2'>
                                        <button onClick={() => {
                                            // removeRoutingComponent(key)
                                            let temOj = routingList
                                            delete temOj[key]
                                            setRoutingList(temOj)

                                        }} className="flex bg-red-500  hover:bg-red-700 text-brand-500 text-white hover:text-white  border rounded mb-6 items-end justify-end gap-2 whitespace-nowrap p-2 text-navy-700 outline-none dark:text-gray-200">
                                            <span className=" ">
                                                <MdDelete className="h-4 w-4 cursor-pointer" />
                                            </span>
                                        </button>
                                    </div> */}
                                </div>
                            })
                        }
                    </div>
            }

            {
                viewMode != "JSON" && <>
                    {routingList?.map((mData: any, index: number) => {
                        return <div className='my-2 p-2 bg-white border shadow' key={String(mData?.id)}>
                            {mData?.component}
                            <div className='flex justify-end text-center my-3'>
                                <span className='me-2'>
                                <InfoModal heading={"Smart Routes JSON DATA"} info={routingData[index]} />

                                </span>
                               
                                
                                <button onClick={() => {
                                    setDeleteRouteRow(index)
                                    setOpenDeleteRowModal(true)
                                    // let a=[...routingList]
                                    // a?.splice(index, 1)
                                    // let newComData=filterComp?.map((mdata:any,index:number)=>{
                                    //     return {
                                    //         ...mdata,
                                    //         component:   <SmartRouting initialRoutesValues={filterData[index]} getRoutignData={getRoutignData} />
                                    //     }
                                    // })
                                    // let b=[...routingData]
                                    //  b?.splice(index, 1)
                                    // let filterComp = routingList?.filter((f: any, Cindex: number) => {
                                    //     // return f?.id!= mData?.id
                                    //     return Cindex!= index

                                    // })
                                    // let filterData = routingData?.filter((f: any, Cindex: number) => {
                                    //     return Cindex!= index
                                    // })
                                    // setRoutingList(filterComp)
                                    // setRoutingData(filterData)
                                }} 
                                className="flex bg-red-500  hover:bg-red-700 text-brand-500 text-white hover:text-white  border rounded mb-6 items-end justify-end gap-2 whitespace-nowrap p-2 text-navy-700 outline-none dark:text-gray-200"
                                >
                                    <span className=" "> Delete </span>
                                </button>
                            </div>
                        </div>
                    })}
                    <button onClick={() => {
                        crrateSmartRouting()
                    }} className="flex bg-indigo-500 my-2 hover:bg-indigo-700 text-brand-500 text-white hover:text-white  border rounded mb-6 items-end justify-end gap-2 whitespace-nowrap p-2 text-navy-700 outline-none dark:text-gray-200">
                        <BsPlusCircle className="h-5 w-5  " />
                        <span className=" "> Add New Condition </span>
                    </button>
                </>
            }

<Modal isOpen={OpenDeleteRowModal} onClose={() => {
     setOpenDeleteRowModal(false)
     setDeleteRouteRow(null)
}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]"> */}
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px] px-[30px] pt-[35px] pb-[35px] flex flex-col !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold">
               Confirm
              </h1>
              <p>
                {"Do you want delete thie record."}
              </p>

              <div className="mt-5 flex gap-2">
                <button
                  onClick={()=>{
                    setOpenDeleteRowModal(false)
                    setDeleteRouteRow(null)
                  }}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Cancel
                </button>
                <button
                  onClick={()=>{
                    let filterComp = routingList?.filter((f: any, Cindex: number) => {
                        // return f?.id!= mData?.id
                        return Cindex!= deleteRouteRow

                    })
                    let filterData = routingData?.filter((f: any, Cindex: number) => {
                        return Cindex!= deleteRouteRow
                    })
                    setRoutingList(filterComp)
                    setRoutingData(filterData)
                    setOpenDeleteRowModal(false)
                    setDeleteRouteRow(null)
                  }}
                  className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                 
                   { "Delete"}
                
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
        </div>
    )
}

export default SmartRoutings