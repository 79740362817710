import React, { useState, useRef, useEffect } from "react";
import {
  MdAdd,
  MdCancel,
  MdCheck,
  MdCheckCircle,
  MdError,
  MdExpand,
  MdFileCopy,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowRight,
  MdOutlineCancel,
  MdOutlineReplayCircleFilled,
  MdRemove,
  MdTimer,
} from "react-icons/md";
import { RiRotateLockFill } from "react-icons/ri";

const StatusRender = ({ status, value }: { status: string; value: string }) => {
  return (
    <>
      {status.toLowerCase() == "COMPLETED".toLowerCase() && (
        <>
          <MdCancel className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 uppercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "false" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="uppercase uppercase first-letter:capitalize">ERRORED</p>
        </>
      )}
      {status === "true" && (
        <>
          <MdCheckCircle className="h-5 w-5 text-teal-500" />
          <p className="bg-green-100 py-1 pl-0 pr-1 uppercase uppercase first-letter:capitalize dark:bg-green-50">
            APPROVED
          </p>
        </>
      )}
      {status === "CAPTURED" && (
        <>
          <MdCheckCircle className="h-5 w-5 text-teal-500" />
          <p className="rounded-lg bg-green-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-green-50">
            <span className="px-2 text-center uppercase text-green-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "APPROVED" && (
        <>
          <MdCheckCircle className="h-5 w-5 text-teal-500" />
          <p className="rounded-lg bg-green-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-green-50">
            <span className="px-2 text-center uppercase text-green-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "CREATED" && (
        <>
          <MdCheckCircle className="h-5 w-5 text-teal-500" />
          <p className="rounded-lg bg-green-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-green-50">
            <span className="px-2 text-center uppercase text-green-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "AUTHORIZED" && (
        <>
          <RiRotateLockFill className="h-5 w-5 text-orange-400" />
          <p className="rounded-lg bg-orange-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-orange-50">
            <span className="px-2 text-center uppercase text-orange-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "REFUNDED" && (
        <>
          <MdOutlineReplayCircleFilled className="h-5 w-5 text-blue-500" />
          <p className="rounded-lg bg-blue-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-blue-50">
            <span className="px-2 text-center uppercase text-blue-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "DECLINED" && (
        <>
          <MdCancel className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "PENDING" && (
        <>
          <MdTimer className="h-5 w-5 text-orange-400" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-amber-400">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "ERRORED" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "CANCELLED" && (
        <>
          <MdCancel className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "FAILED" && (
        <>
          <MdCancel className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "REFUND DECLINED" && (
        <>
          <MdOutlineReplayCircleFilled className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value
                .toLowerCase()
                .replace(/\b\w/g, (value) => value.toUpperCase())}
            </span>
          </p>
        </>
      )}
      {status === "CHARGEBACK" && (
        <>
          <MdOutlineReplayCircleFilled className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}

      {status === "REJECTED" && (
        <>
          <MdCancel className="h-5 w-5 text-red-500" />
          <p className="rounded-lg bg-red-100 py-1 pl-0 pr-1 text-center uppercase first-letter:capitalize dark:bg-red-50">
            <span className="px-2 text-center uppercase text-red-500">
              {value}
            </span>
          </p>
        </>
      )}

      {status === "ALERTED" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "HELD" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
      {status === "DUPLICATE" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
      {/* DISPUTE */}
      {status === "DISPUTE" && (
        <>
          <MdError className="h-5 w-5 text-amber-500" />
          <p className="rounded-lg bg-amber-100 py-1 pl-0 pr-1 lowercase first-letter:capitalize dark:bg-amber-50">
            <span className="px-2 text-center uppercase text-amber-500">
              {value}
            </span>
          </p>
        </>
      )}
    </>
  );
};

export default StatusRender;
