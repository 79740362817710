import { BsEyeFill, BsPlusCircle } from "react-icons/bs";
import Card from "components/card";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
// import { Select as AntSelect } from 'antd';
import Checkbox from "components/checkbox";
// import { MultiSelect } from 'react-multi-select-component';
import DateRange from "components/fields/DateRange";
import { useEffect, useState } from "react";
// import { getClientDetails } from 'api/dashboard';
import toast from "react-hot-toast";
// import { currencyCodes, getCurrencySymbol } from 'utils/currencyList';
// import { getClientPaymentMethodDetails } from 'api/clients';
// import SummaryReport from './summaryReport';
import moment from "moment";
// import { generateSettlementReport, updateSettlementRecord } from 'api/settlement';
import { getId, getUserId } from "utils/auth";
import { BiEdit } from "react-icons/bi";
// import ReactDatePicker from 'react-datepicker';
import InputField from "components/fields/InputField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { getProfile } from "api/users";
import User2FAModal from "components/userVerification/user2FAModal";
import {
  createAPItoken,
  getAPIAccessTokenById,
  updateAPITokenRecord,
  verifyBackOfficeAPI,
} from "api/api_access";
import { MdEdit } from "react-icons/md";
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

function CrerateSettlementReport(props: {
  allOrgDetails: any;
  fetchAllsettlement: () => void;
  info?: any;
  isInfo?: boolean;
  roleData?: any;
}) {
  const { allOrgDetails, fetchAllsettlement, info, isInfo, roleData } = props;

  console.log("infoooo", info);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectOrg, setSelectOrg] = useState<any>();
  const [apiAccessFormData, setApiAccessFormData] = useState<any>({});
  const [userVerificationData, setUserVerificationData] = useState<any>({});
  const [isOpen2FAPopup, setIsOpen2FAPopup] = useState<boolean>(false);
  const getTokenDetailsById = () => {
    getAPIAccessTokenById(info?.row?.original?.id)
      .then((res) => {
        onOpen();
        let resData = res[0];
        setApiAccessFormData((prev: object) => {
          return {
            api_token: resData?.name,
            daily_api_limit: resData?.api_hit_limit,
            isExpiry_ckbox: true,
            expiry_date: new Date(resData?.expiry_date),
            status: resData?.status,
          };
        });

        let permissions = resData?.endpoints_url_permissions;
        if (permissions) {
          if (permissions?.client_operetaion_permission) {
            setSelectedClient(
              ClientData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.client_operetaion_permission[key];
              })
            );
          }
          if (permissions?.user_operetaion_permission) {
            setSelectedUser(
              UserData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.user_operetaion_permission[key];
              })
            );
          }

          if (permissions?.organization_operetaion_permission) {
            setSelectedOrganization(
              OrganizationData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.organization_operetaion_permission[key];
              })
            );
          }

          if (permissions?.transaction_operation_permission) {
            setSelectedTransaction(
              TransactionData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.transaction_operation_permission[key];
              })
            );
          }

          if (permissions?.gateways_operetaion_permission) {
            setSelectedGateways(
              GatewaysData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.gateways_operetaion_permission[key];
              })
            );
          }

          if (permissions?.subscriptions_operetaion_permission) {
            setSelectedSubscriptions(
              SubscriptionData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.subscriptions_operetaion_permission[key];
              })
            );
          }
          if (permissions?.token_operation_permission) {
            setSelectedTokens(
              TokenData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.token_operation_permission[key];
              })
            );
          }

          if (permissions?.role_operation_permission) {
            setSelectedRoles(
              RolesData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.role_operation_permission[key];
              })
            );
          }
          if (permissions?.dashboard_operation_permission) {
            setSelectedDashboard(
              DashboardData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.dashboard_operation_permission[key];
              })
            );
          }
          if (permissions?.client_gateway_operations) {
            setSelectedClientGateway(
              ClientGatewayData?.filter((item) => {
                const key = item.toLowerCase().replace(/ /g, "_");
                return permissions?.client_gateway_operations[key];
              })
            );
          }
        }
      })
      .catch((error) => {
        setApiAccessFormData({});
      });
  };

  const [selectPaymentMethod, setSelectPaymentMethod] = useState<any>([]);

  const handleClose = () => {
    onClose();
    setApiAccessFormData({});
    setSelectedClient([]);
    setSelectedUser([]);
    setSelectedOrganization([]);
    setSelectedTransaction([]);
    setSelectedGateways([]);
    setSelectedSubscriptions([]);
  };

  const ClientData = [
    "Create client",
    "Get all clients",
    "Get client details",
    "Update client details",
    "Delete client",
  ];

  const UserData = [
    "Register user",
    "Verify otp",
    "Get user profile",
    "Get all users details",
    "Create 2fa auth details",
    "Update user profile",
    "Update user password",
    "Update user details",
    "Manage 2fa authentication",
    "Delete user profile",
    "Delete user details",
  ];
  const OrganizationData = [
    "Create organization",
    "Get all organization",
    "Get organization details",
    "Update organization details",
    "Delete organization",
  ];
  const TransactionData = [
    "Get all payments details",
    "Get payment history",
    "Refund payment",
    "Retry payment",
    "Change payment status",
  ];
  const GatewaysData = ["Get all gateways", "Get gateway details"];
  const SubscriptionData = [
    "Get all subscription",
    "Get subscription details",
    "Cancel subscription",
  ];
  const TokenData = ["Get all token", "Get token"];
  const RolesData = [
    "Create role",
    "Get role by id",
    "Get all roles",
    "Update role",
    "Delete role",
  ];

  const DashboardData = [
    "Get dashboard info",
    "Get dashboard client org_details",
    "Get wave chart",
    "Get transaction analysis",
    "Get transaction approved gateways",
    "Get transaction decline gateways",
    "Get transaction approved currency",
    "Get transaction decline currency",
    "Get client by organization",
  ];

  const ClientGatewayData = [
    "Create client gateway",
    "Delete client gateway",
    "Update client gateway",
    "Get client all gateways",
    "Get client gateway details",
    "Get charge options details",
  ];

  const [selectedClient, setSelectedClient] = useState<any>([]);
  const isAllSelectedClient =
    ClientData?.length > 0 && selectedClient?.length === ClientData?.length;

  const [selectedUser, setSelectedUser] = useState<any>([]);
  const isAllSelectedUser =
    UserData?.length > 0 && selectedUser?.length === UserData?.length;

  const [selectedOrganization, setSelectedOrganization] = useState<any>([]);
  const isAllSelectedOrganization =
    OrganizationData?.length > 0 &&
    selectedOrganization?.length === OrganizationData?.length;

  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);
  const isAllSelectedTransaction =
    TransactionData?.length > 0 &&
    selectedTransaction?.length === TransactionData?.length;

  const [selectedGateways, setSelectedGateways] = useState<any>([]);
  const isAllSelectedGateways =
    GatewaysData?.length > 0 &&
    selectedGateways?.length === GatewaysData?.length;

  const [selecteSubscriptions, setSelectedSubscriptions] = useState<any>([]);
  const isAllSelectedSubscriptions =
    SubscriptionData?.length > 0 &&
    selecteSubscriptions?.length === SubscriptionData?.length;

  const [selecteTokens, setSelectedTokens] = useState<any>([]);
  const isAllSelectedTokens =
    TokenData?.length > 0 && selecteTokens?.length === TokenData?.length;

  const [selecteRoles, setSelectedRoles] = useState<any>([]);
  const isAllSelectedRoles =
    RolesData?.length > 0 && selecteRoles?.length === RolesData?.length;

  const [selecteDashboard, setSelectedDashboard] = useState<any>([]);
  const isAllSelectedDashboard =
    DashboardData?.length > 0 &&
    selecteDashboard?.length === DashboardData?.length;

  const [selecteClientGateway, setSelectedClientGateway] = useState<any>([]);
  const isAllSelectedClientGateway =
    ClientGatewayData?.length > 0 &&
    selecteClientGateway?.length === ClientGatewayData?.length;

  const handleValueChange = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    setApiAccessFormData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const fetchProfileData = () => {
    getProfile()
      .then((res) => {
        // const userData = res
        // console.log("resres", res)
        if (true) {
          setUserVerificationData((prev: any) => {
            return {
              ...prev,
              is_auth_2fa_activate: res[0]?.auth_2fa,
            };
          });
          setIsOpen2FAPopup(true);
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const onClose2FAPopup = () => {
    setIsOpen2FAPopup(false);
    setUserVerificationData({});
  };

  const verify2FACreateAccessToken = () => {
    let payload = {
      password: "",
      otp: "",
      backoffice_access_token_id: "",
    };
    verifyBackOfficeAPI(payload)
      .then((res) => {})
      .catch((error) => {});
  };

  const handleCheckboxChange = (event: any) => {
    const { value, id } = event.target;

    if (value === "all") {
      if (id === "client") {
        setSelectedClient(
          selectedClient?.length === ClientData?.length ? [] : ClientData
        );
      }
      if (id === "user") {
        setSelectedUser(
          selectedUser?.length === UserData?.length ? [] : UserData
        );
      }
      if (id === "organization") {
        setSelectedOrganization(
          selectedOrganization?.length === OrganizationData?.length
            ? []
            : OrganizationData
        );
      }
      if (id === "transaction") {
        setSelectedTransaction(
          selectedTransaction?.length === TransactionData?.length
            ? []
            : TransactionData
        );
      }
      if (id === "gateway") {
        setSelectedGateways(
          selectedGateways?.length === GatewaysData?.length ? [] : GatewaysData
        );
      }
      if (id === "subscription") {
        setSelectedSubscriptions(
          selecteSubscriptions?.length === SubscriptionData?.length
            ? []
            : SubscriptionData
        );
      }
      if (id === "token") {
        setSelectedTokens(
          selecteTokens?.length === TokenData?.length ? [] : TokenData
        );
      }
      if (id === "role") {
        setSelectedRoles(
          selecteRoles?.length === RolesData?.length ? [] : RolesData
        );
      }
      if (id === "dashboard") {
        setSelectedDashboard(
          selecteDashboard?.length === DashboardData?.length
            ? []
            : DashboardData
        );
      }

      if (id === "clientgateway") {
        setSelectedClientGateway(
          selecteClientGateway?.length === ClientGatewayData?.length
            ? []
            : ClientGatewayData
        );
      }
    } else if (value === "-1") {
      if (id === "client") {
        setSelectedClient([]);
      }
      if (id === "user") {
        setSelectedUser([]);
      }
      if (id === "organization") {
        setSelectedOrganization([]);
      }
      if (id === "transaction") {
        setSelectedTransaction([]);
      }
      if (id === "gateway") {
        setSelectedGateways([]);
      }
      if (id === "subscription") {
        setSelectedSubscriptions([]);
      }
      if (id === "token") {
        setSelectedTokens([]);
      }
      if (id === "role") {
        setSelectedRoles([]);
      }
      if (id === "dashboard") {
        setSelectedDashboard([]);
      }
      if (id === "clientgateway") {
        setSelectedClientGateway([]);
      }
    } else {
      if (id === "client") {
        if (selectedClient?.includes(value)) {
          setSelectedClient(
            selectedClient?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedClient([...selectedClient, value]);
        }
      }
      if (id === "user") {
        if (selectedUser?.includes(value)) {
          setSelectedUser(
            selectedUser?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedUser([...selectedUser, value]);
        }
      }
      if (id === "organization") {
        if (selectedOrganization?.includes(value)) {
          setSelectedOrganization(
            selectedOrganization?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedOrganization([...selectedOrganization, value]);
        }
      }
      if (id === "transaction") {
        if (selectedTransaction?.includes(value)) {
          setSelectedTransaction(
            selectedTransaction?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedTransaction([...selectedTransaction, value]);
        }
      }
      if (id === "gateway") {
        if (selectedGateways?.includes(value)) {
          setSelectedGateways(
            selectedGateways?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedGateways([...selectedGateways, value]);
        }
      }
      if (id === "subscription") {
        if (selecteSubscriptions?.includes(value)) {
          setSelectedSubscriptions(
            selecteSubscriptions?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedSubscriptions([...selecteSubscriptions, value]);
        }
      }
      if (id === "token") {
        if (selecteTokens?.includes(value)) {
          setSelectedTokens(
            selecteTokens?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedTokens([...selecteTokens, value]);
        }
      }
      if (id === "role") {
        if (selecteRoles?.includes(value)) {
          setSelectedRoles(
            selecteRoles?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedRoles([...selecteRoles, value]);
        }
      }
      if (id === "dashboard") {
        if (selecteDashboard?.includes(value)) {
          setSelectedDashboard(
            selecteDashboard?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedDashboard([...selecteDashboard, value]);
        }
      }
      if (id === "clientgateway") {
        if (selecteClientGateway?.includes(value)) {
          setSelectedClientGateway(
            selecteClientGateway?.filter((data: any) => {
              return data !== value;
            })
          );
        } else {
          setSelectedClientGateway([...selecteClientGateway, value]);
        }
      }
    }
  };

  const handleSubmit = () => {
    let data = {
      name: apiAccessFormData?.api_token,
      expiry_date: apiAccessFormData?.isExpiry_ckbox
        ? moment(apiAccessFormData?.expiry_date).format("YYYY-MM-DD, HH:mm:ss")
        : null,
      // "expiry_date": moment(apiAccessFormData?.expiry_date).format("YYYY-MM-DD, HH:mm:ss"),
      api_hit_limit: apiAccessFormData?.daily_api_limit,
      status: apiAccessFormData?.status || null,
      endpoints_url_permissions: {
        client_operetaion_permission: {
          create_client: selectedClient.includes("Create client"),
          update_client_details: selectedClient.includes(
            "Update client details"
          ),
          get_all_clients: selectedClient.includes("Get all clients"),
          get_client_details: selectedClient.includes("Get client details"),
          delete_client: selectedClient.includes("Delete client"),
        },
        user_operetaion_permission: {
          register_user: selectedUser.includes("Register user"),
          verify_otp: selectedUser.includes("Verify otp"),
          get_user_profile: selectedUser.includes("Get user profile"),
          get_all_users_details: selectedUser.includes("Get all users details"),
          create_2fa_auth_details: selectedUser.includes(
            "Create 2fa auth details"
          ),
          update_user_profile: selectedUser.includes("Update user profile"),
          update_user_password: selectedUser.includes("Update user password"),
          update_user_details: selectedUser.includes("Update user details"),
          manage_2fa_authentication: selectedUser.includes(
            "Manage 2fa authentication"
          ),
          delete_user_profile: selectedUser.includes("Delete user profile"),
          delete_user_details: selectedUser.includes("Delete user details"),
        },
        organization_operetaion_permission: {
          create_organization: selectedOrganization.includes(
            "Create organization"
          ),
          get_all_organization: selectedOrganization.includes(
            "Get all organization"
          ),
          get_organization_details: selectedOrganization.includes(
            "Get organization details"
          ),
          update_organization_details: selectedOrganization.includes(
            "Update organization details"
          ),
          delete_organization: selectedOrganization.includes(
            "Delete organization"
          ),
        },
        transaction_operation_permission: {
          get_all_payments_details: selectedTransaction.includes(
            "Get all payments details"
          ),
          get_payment_history: selectedTransaction.includes(
            "Get payment history"
          ),
          refund_payment: selectedTransaction.includes("Refund payment"),
          retry_payment: selectedTransaction.includes("Retry payment"),
          change_payment_status: selectedTransaction.includes(
            "Change payment status"
          ),
        },
        gateways_operetaion_permission: {
          get_all_gateways: selectedGateways.includes("Get all gateways"),
          get_gateway_details: selectedGateways.includes("Get gateway details"),
        },
        subscriptions_operetaion_permission: {
          get_all_subscription: selecteSubscriptions.includes(
            "Get all subscription"
          ),
          get_subscription_details: selecteSubscriptions.includes(
            "Get subscription details"
          ),
          cancel_subscription: selecteSubscriptions.includes(
            "Cancel subscription"
          ),
        },
        token_operation_permission: {
          get_all_token: selecteTokens.includes("Get all token"),
          get_token: selecteTokens.includes("Get token"),
        },
        role_operation_permission: {
          create_role: selecteRoles.includes("Create role"),
          get_role_by_id: selecteRoles.includes("Get role by id"),
          get_all_roles: selecteRoles.includes("Get all roles"),
          update_role: selecteRoles.includes("Update role"),
          delete_role: selecteRoles.includes("Delete role"),
        },
        dashboard_operation_permission: {
          get_dashboard_info: selecteDashboard.includes("Get dashboard info"),
          get_dashboard_client_org_details: selecteDashboard.includes(
            "Get dashboard client org_details"
          ),
          get_wave_chart: selecteDashboard.includes("Get wave chart"),
          get_transaction_analysis: selecteDashboard.includes(
            "Get transaction analysis"
          ),
          get_transaction_approved_gateways: selecteDashboard.includes(
            "Get transaction approved gateways"
          ),
          get_transaction_decline_gateways: selecteDashboard.includes(
            "Get transaction decline gateways"
          ),
          get_transaction_approved_currency: selecteDashboard.includes(
            "Get transaction approved currency"
          ),
          get_transaction_decline_currency: selecteDashboard.includes(
            "Get transaction decline currency"
          ),
          get_client_by_organization: selecteDashboard.includes(
            "Get client by organization"
          ),
        },
        client_gateway_operations: {
          create_client_gateway: selecteClientGateway.includes(
            "Create client gateway"
          ),
          delete_client_gateway: selecteClientGateway.includes(
            "Delete client gateway"
          ),
          update_client_gateway: selecteClientGateway.includes(
            "Update client gateway"
          ),
          get_client_all_gateways: selecteClientGateway.includes(
            "Get client all gateways"
          ),
          get_client_gateway_details: selecteClientGateway.includes(
            "Get client gateway details"
          ),
          get_charge_options_details: selecteClientGateway.includes(
            "Get charge options details"
          ),
        },
      },
    };
    if (info?.row?.original?.id) {
      updateAPITokenRecord(data, info?.row?.original?.id)
        .then((res) => {
          toast.success("Success");
          fetchAllsettlement();
          handleClose();
        })
        .catch((error) => {
          toast.error("Something went wrong!");
        })
        .finally(() => {});
    } else {
      createAPItoken(data)
        .then((res) => {
          toast.success("Success");
          fetchAllsettlement();
          handleClose();
        })
        .catch((error) => {
          toast.error("Something went wrong!");
        })
        .finally(() => {});
    }
  };

  const handleVerify2FA = () => {
    let payload = {
      password: userVerificationData?.password,
      otp: userVerificationData?.passCode,
      backoffice_access_token_id:
        userVerificationData?.backoffice_access_token_id,
    };
    verifyBackOfficeAPI(payload)
      .then((res) => {
        const isVerified = res?.is_verified;
        toast.success(res?.message);
        if (isVerified) {
          onOpen();
        }
        onClose2FAPopup();
        // setUserVerificationData((prev: any) => {
        //     return {
        //         ...prev,
        //         is_verified: isVerified

        //     }
        // })
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };

  console.log("apiAccessFormData", apiAccessFormData);

  return (
    <>
      <span>
        {isInfo ? (
          roleData?.[0]?.api?.value?.view_api && (
            <BsEyeFill
              className="h-5 w-5 cursor-pointer text-blue-500"
              onClick={() => {
                getTokenDetailsById();
              }}
            />
          )
        ) : info?.row?.original?.id ? (
          roleData?.[0]?.api?.value?.edit_api && (
            <MdEdit
              // {false ? <BiEdit

              className="h-5 w-5 cursor-pointer text-blue-500"
              onClick={() => {
                getTokenDetailsById();

                // setApiAccessFormData((prev: any) => {
                //     return {
                //         ...prev,
                //         "api_token": info?.row?.original?.name,
                //         "daily_api_limit": info?.row?.original?.api_hit_limit,
                //         "isExpiry_ckbox": true,
                //         "expiry_date": new Date(info?.row?.original?.expiry_date),

                //     }
                // })
                // onOpen();
              }}
            />
          )
        ) : (
          <Card extra="w-fit px-1 cursor-pointer">
            <button
              onClick={() => {
                fetchProfileData();
                // onOpen();
              }}
              className="flex items-center justify-center gap-2 whitespace-nowrap p-5 text-navy-700 outline-none dark:text-gray-200"
            >
              <BsPlusCircle className="h-5 w-5 text-brand-500" />
              <span className="text-brand-500">{"Create API Token"}</span>
            </button>
          </Card>
        )}
      </span>

      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="top-[12vh] !z-[1002] !m-auto sm:my-8 sm:w-full sm:max-w-lg lg:max-w-3xl md:top-[11vh]"> */}
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {info?.row?.original?.id
                  ? isInfo?"View API Token":"Edit API Token"
                  : "Create API Token"}
              </h1>

              <div className="max-h-[calc(100vh-200px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 sm:max-h-[calc(100vh-300px)]">
                <div className="my-2">
                  {/* <label className=''>API Secret K</label> */}
                  <label
                    htmlFor="api_token"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    API Token Name
                  </label>
                  <InputField
                    variant="auth"
                    extra="mb-2 w-full "
                    label=""
                    placeholder="API Token Name"
                    id={`api_token`}
                    type="text"
                    value={apiAccessFormData?.api_token}
                    disabled={isInfo}
                    // state={infoErr?.fixed ? "error" : ""}
                    onChange={handleValueChange}
                  />
                </div>

                <div className="my-2">
                  <label
                    htmlFor="number-input"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Daily API Limit
                  </label>
                  <input
                    type="number"
                    className="block w-full rounded-lg border p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    placeholder="Daily API Limit"
                    id={`daily_api_limit`}
                    value={apiAccessFormData?.daily_api_limit}
                    onChange={(e: any) => {
                      if (true) {
                        handleValueChange(e);
                      }
                    }}
                    disabled={isInfo}
                  />
                </div>

                {(isInfo || info?.row?.original?.id) && (
                  <div className="my-2">
                    <label
                      htmlFor="status"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      className=" block w-full rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      value={apiAccessFormData?.status}
                      onChange={(e) => {
                        handleValueChange(e);
                      }}
                      disabled={isInfo || !roleData?.[0]?.api?.value?.block_api}
                    >
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="BLOCK">BLOCK</option>
                    </select>
                  </div>
                )}
                <div className="p-2 ">
                  <div className="flex items-center ">
                    <div className="flex items-center">
                      <Checkbox
                        id={"isExpiry_ckbox"}
                        value={!apiAccessFormData?.isExpiry_ckbox}
                        className={`${
                          true ? "h-4 w-4 dark:bg-gray-700" : "h-4 w-4"
                        } mr-3`}
                        color={false || null ? "gray" : ""}
                        checked={apiAccessFormData?.isExpiry_ckbox}
                        onChange={(e: any) => {
                          const name = e.target.id;
                          const value = e.target.value;
                          setApiAccessFormData((prev: any) => {
                            return {
                              ...prev,
                              [name]:
                                value == "true"
                                  ? true
                                  : value == "false"
                                  ? false
                                  : null,
                            };
                          });
                        }}
                        disabled={isInfo}
                      />
                      <span className="mb-0 block text-sm font-medium text-gray-900 dark:text-white">
                        Expiry Date
                      </span>
                    </div>

                    <div></div>
                  </div>
                  {apiAccessFormData?.isExpiry_ckbox && (
                    <div>
                      {/* <DatePicker className='border p-3 rounded-2' placeholderText='Expiry Date' onChange={(date) => { }} /> */}

                      <div className="relative mt-2 max-w-sm">
                        <DatePicker
                          id="expiry_date"
                          name="expiry_date"
                          placeholderText="Select Expiry Date"
                          className={`bg-gray/0 mt-2 flex h-10 w-full items-center justify-center rounded-xl border p-3 text-sm text-gray-700 outline-none`}
                          // selected={new Date(new Date(info?.created_at?.replace("GMT", "")).toUTCString())}

                          selected={apiAccessFormData?.expiry_date}
                          timeInputLabel="Time:"
                          dateFormat="yyyy-MM-dd HH:mm:ss"
                          onChange={(date: any) => {
                            console.log(
                              "Date",
                              moment(date).format("YYYY-MM-DD, HH:mm:ss")
                            );

                            setApiAccessFormData((prev: any) => {
                              return {
                                ...prev,
                                expiry_date: date,
                              };
                            });
                          }}
                          showTimeSelect
                          timeFormat="HH:mm:ss"
                          timeIntervals={15}
                          disabled={isInfo}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {
                  <div className="my-3">
                    <label className=" mb-0 block text-sm font-medium text-gray-900 dark:text-white">
                      All Backoffice APIs
                    </label>
                    {/* <Card> */}
                    <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="client"
                              value={isAllSelectedClient ? -1 : "all"}
                              checked={isAllSelectedClient}
                              onChange={handleCheckboxChange}
                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Client
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {ClientData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="client"
                                    value={data}
                                    checked={selectedClient.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="user"
                              value={isAllSelectedUser ? -1 : "all"}
                              checked={isAllSelectedUser}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              User
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {UserData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="user"
                                    value={data}
                                    checked={selectedUser.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="organization"
                              value={isAllSelectedOrganization ? -1 : "all"}
                              checked={isAllSelectedOrganization}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Organization
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {OrganizationData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="organization"
                                    value={data}
                                    checked={
                                      selectedOrganization.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="transaction"
                              value={isAllSelectedTransaction ? -1 : "all"}
                              checked={isAllSelectedTransaction}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Transaction
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {TransactionData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="transaction"
                                    value={data}
                                    checked={
                                      selectedTransaction.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="gateway"
                              value={isAllSelectedGateways ? -1 : "all"}
                              checked={isAllSelectedGateways}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Gateways
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {GatewaysData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="gateway"
                                    value={data}
                                    checked={
                                      selectedGateways.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="subscription"
                              value={isAllSelectedSubscriptions ? -1 : "all"}
                              checked={isAllSelectedSubscriptions}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Subscriptions
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {SubscriptionData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="subscription"
                                    value={data}
                                    checked={
                                      selecteSubscriptions.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="token"
                              value={isAllSelectedTokens ? -1 : "all"}
                              checked={isAllSelectedTokens}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Tokens
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {TokenData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="token"
                                    value={data}
                                    checked={selecteTokens.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="role"
                              value={isAllSelectedRoles ? -1 : "all"}
                              checked={isAllSelectedRoles}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Roles
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {RolesData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="role"
                                    value={data}
                                    checked={selecteRoles.indexOf(data) > -1}
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="dashboard"
                              value={isAllSelectedDashboard ? -1 : "all"}
                              checked={isAllSelectedDashboard}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Dashboard
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {DashboardData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="dashboard"
                                    value={data}
                                    checked={
                                      selecteDashboard.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className="mx-1 mt-8 max-w-md overflow-hidden rounded-xl bg-white shadow-lg">
                        <div className="p-0">
                          <div className="flex gap-2 bg-indigo-50 p-2 px-4">
                            <Checkbox
                              id="clientgateway"
                              value={isAllSelectedClientGateway ? -1 : "all"}
                              checked={isAllSelectedClientGateway}
                              onChange={handleCheckboxChange}
                              disabled={isInfo}

                              //   color={viewMode ? "gray" : ""}
                            />

                            <h2 className=" font-lg text-base text-navy-700">
                              Client Gateway
                            </h2>
                          </div>
                          <div className="max-h-72 overflow-auto overflow-x-hidden px-6 px-[30px] py-3 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                            {ClientGatewayData?.map((data, index) => {
                              return (
                                <div className="mt-3 flex gap-2">
                                  <Checkbox
                                    id="clientgateway"
                                    value={data}
                                    checked={
                                      selecteClientGateway.indexOf(data) > -1
                                    }
                                    onChange={handleCheckboxChange}
                                    disabled={isInfo}

                                    // disabled={viewMode}
                                    // color={viewMode ? "gray" : ""}
                                  />
                                  <p className="font-medium text-navy-600">
                                    {data}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* </Card> */}
                  </div>
                }
              </div>
              <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                {true && (
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isInfo}
                    className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {info?.row?.original?.id ? "Update" : "Create"}
                  </button>
                )}
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen2FAPopup} onClose={onClose2FAPopup}>
        <ModalOverlay className="z-20   bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto   !w-max min-w-[350px] !max-w-[85%] shadow md:top-[12vh] ">
          <ModalBody className="">
            <Card extra="px-[30px] pt-[35px] pb-[20px] max-w-[450px] flex flex-col !z-[1004] ">
              {!userVerificationData?.is_auth_2fa_activate ? (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To create API Access please verify 2FA code.
                  </p>
                  <br />

                  <p className="">
                    Your account does not meet the necessary requirements in
                    order to create API Access, please visit{" "}
                    <Link to="/admin/profile">profile settings</Link> and
                    activate 2FA code.
                  </p>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onClose2FAPopup}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                  </div>
                </>
              ) : (
                <>
                  <h1 className=" text-2xl font-bold">{"2FA Verification"}</h1>
                  <p className="opacity-50">
                    To create API Access please verify 2FA code.
                  </p>

                  <div className="w-72 bg-white">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={userVerificationData?.password}
                      label="Password *"
                      placeholder="Enter password"
                      id="password"
                      type={`${
                        userVerificationData?.isShow ? "text" : "password"
                      }`}
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value;
                        setUserVerificationData((prev: any) => {
                          return {
                            ...prev,
                            [name]: value,
                          };
                        });
                      }}
                      passwordShow={userVerificationData?.isShow}
                      setPasswordShow={() =>
                        setUserVerificationData((prev: any) => {
                          return {
                            ...prev,
                            isShow: !prev?.isShow,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="w-72">
                    <InputField
                      variant="auth"
                      extra="mt-3 w-full"
                      value={userVerificationData?.passCode}
                      label="2FA Code *"
                      placeholder="Enter 2FA 6 digit code"
                      id="passCode"
                      type="number"
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value?.trim();
                        if (value?.length <= 6) {
                          setUserVerificationData((prev: any) => {
                            return {
                              ...prev,
                              [name]: value,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="mt-3 flex  justify-end gap-2">
                    <button
                      onClick={onClose2FAPopup}
                      className="linear rounded-xl bg-gray-100 px-3 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                    >
                      Close
                    </button>{" "}
                    <button
                      className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      onClick={() => {
                        handleVerify2FA();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* <User2FAModal userVerificationData={userVerificationData} setUserVerificationData={setUserVerificationData} title={"create API Token"} isOpen2FAPopup={isOpen2FAPopup} onClose2FAPopup={onClose2FAPopup} /> */}
    </>
  );
}

export default CrerateSettlementReport;
