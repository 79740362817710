import authRequest from "../utils/authRequest";


export const getAllPayouts = (
    page: number,
    pageSize: number = 10,
    type: any,
    searchTexts: any

): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            // url: "/api/v1/payout/details/1/10?is_live=",
            // url: "/api/v1/payout/details"+ "?is_live=" + "false",
            url: `/api/v1/payout/details?is_live=${type == "all" ? "all" : type == "live" ? true : false}&page=${page}&per_page=${pageSize}&searchTexts=${searchTexts ? searchTexts : ""}`,

            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


