import React from 'react'

function NotFoundPage() {
    return (
        <div>
            {/* <div className='text-center'>
                <p>
                    <p>{window.location?.pathname}</p>

                </p>
            </div> */}
            <div className='flex justify-center items-center flex-col   !h-[100vh]' >
                <h3>404 <span className='opacity-50'>| This page could not be found.</span></h3>
                <br />
                <p className='text-large text-lg'  >Go back <a href='/' className='font-bold text-blue-900'   >Home</a></p>



            </div>


        </div>
    )
}

export default NotFoundPage