import { Routes, Route, Navigate } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';


// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { toast, ToastContainer } from "react-toastify";
import NotFoundPage from "NotFoundPage";
import PaymentApproved from "PaymentApproved";
import PaymentErrored from "PaymentErrored";
import PaymentStatus from "PaymentStatus";

const App = () => {
  return (
    <>


      <Routes>

        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
        {/* <Route path="/payment/approved" element={<PaymentApproved />} /> */}
        {/* <Route path="/payment/failed" element={<PaymentErrored />} /> */}
        {/* <Route path={`/payment/status`} element={<PaymentStatus />} /> */}
        <Route path={`/payment/status/gateway_id/:gateway_id/client_id/:client_id/payment_request_id/:payment_request_id`} element={<PaymentStatus />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default App;
