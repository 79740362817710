import authRequest from "../utils/authRequest";

// https://backend-dev.bm2pay.com/api/v1/client/logs?page=1&per_page=10

export const getLogs = (
    page: number,
    pageSize: number = 50,
    searchTexts?: any,
    logModule?:any

): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/${logModule}/logs?page=${page}&per_page=${pageSize}&searchTexts=${searchTexts ? searchTexts : ""}`,
            method: "get",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};