import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useState } from "react";
import { SettlementRowObj } from "..";
import CreatePaymentMethoddModal from "./CreatePaymentMethod";
import {
  MdApproval,
  MdCancel,
  MdCheckBox,
  MdCheckCircle,
  MdCheckCircleOutline,
  MdCircle,
  MdDelete,
  MdOutlineCheck,
  MdOutlineCircle,
} from "react-icons/md";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Searchbox from "components/fields/Searchbox";
// import { getImaDownge } from "utils/commonFunction";
import { BsEyeFill, BsDownload } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import Pagination from "components/pagination";
import toast from "react-hot-toast";
import {
  approvedSettlement,
  deleteSettlementReport,
  DownloadCSVSettlement,
  downloadSettlementReport,
  checkTransactionAvailableSettlementReport,
} from "api/settlement";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import CrerateSettlementReport from "./CrerateSettlementReport";
import SettlementReport from "./settlementReport";
import InputField from "components/fields/InputField";
import { error } from "console";
import { FaCheckCircle } from "react-icons/fa";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function SettlementTable(props: {
  tableData: any;
  fetchAllsettlement: () => void;
  isLoading: boolean;
  page: number;
  setPage: any;
  totalpage: number;
  totalItems: number;
  currentPage: number;
  pageSize: number;
  setPageSize: any;
  roleData: any;
  timeZone: any;
  allCardType: any;
  onValueChange: (value: string) => void;
  allOrgDetails: any;
}) {
  const {
    tableData,
    fetchAllsettlement,
    page,
    setPage,
    currentPage,
    totalpage,
    totalItems,
    pageSize,
    setPageSize,
    roleData,
    timeZone,
    allCardType,
    onValueChange,
    allOrgDetails,
  } = props;
  let defaultData = tableData;
  let showPaymentName =
    roleData[0]?.payment_method?.value?.show_payment_method_name;
  //console.log("allCardType3333",allCardType);
  console.log("tableData", tableData);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpenDelete, setIsopenDelete] = useState(false);
  const [DeleteRowId, setDeleteRowId] = useState("");
  const [isOpenApprove, setIsopenApprove] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [isDownloadFile, setIsDownloadFile] = useState(false);

  const [loadingApproveSettlement, setLoadingApproveSettlement] = useState(false);
  const [isTransctionAvailable, setIsTransctionAvailable] = useState(false);

  const handelOnDownloadCSV = (sid: any) => {
    // setLoading(true);
    downloadSettlementReport(sid)
      .then((response) => {
        const csvText = response;
        const blob = new Blob([csvText], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "generated_data.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Download Completed");
        setTimeout(() => {
          // setLoading(false);
        }, 2000);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ??
          "Something went wrong while downloading CSV"
        );
        setTimeout(() => {
          // setLoading(false);
        }, 2000);
      });
  };

  const deleteSettlementReportFun = (id: any) => {
    // let id:any={
    //     id:id
    // }
    deleteSettlementReport(DeleteRowId)
      .then((res) => {
        toast.success("Record deleted successfully!");
        fetchAllsettlement();
        handleClose();
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };
  const handleDownloadReport = (data: any) => {
    setIsDownloadFile(true);
    console.log("data :::::::::::::", { data })
    downloadSettlementReport(data?.id)
      .then((res) => {
        console.log("testtets", res);
        toast.success("Success!");
        data.takeAPIResponse(res);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setIsDownloadFile(false);
      });
  };

  const [TableApproveData, setTableApproveData] = useState<any>();

  const handlePressonEye = (infoData: any) => {
    setLoadingApproveSettlement(true)
    let settlementId = infoData?.row?.original?.id
    checkTransactionAvailableSettlementReport(settlementId)
      .then((data: any) => {
        console.log("then :::::::::: inside", { data })
        if (data?.Is_transction_available) {
          setTableApproveData(infoData);
          setIsopenApprove(true);
        } else {
          toast.error("Oops! There are no transaction of clients for the organization in the selected time period.");
        }
      }).catch((error: any) => console.log(error))
      .finally(() => {
        setLoadingApproveSettlement(false)
      })
  };
  const columns = [
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Organization Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.organization?.name}

        </div>
      ),
    }),
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Client Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info?.row.original?.client
            ?.map((m: any) => {
              return m?.name;
            })
            .join(", ")}
        </div>
      ),
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Created Date
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.created_at}
        </div>
      ),
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Total Payout
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.is_settlement_approved
            ? info.row.original?.total_payout ? "$" + Number(info.row.original?.total_payout)?.toFixed(2) : "" : "-"}
        </div>
      ),
    }),

    columnHelper.accessor("name", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          ACTION
        </p>
      ),
      cell: (info: any) => (
        <p className="flex items-center gap-3 text-lg font-bold">
          <button className="outline-none disabled:cursor-not-allowed disabled:opacity-50">
            {roleData?.[0]?.settlement?.value?.approve_settlement &&
              (!info?.row?.original?.is_settlement_approved ? (
                <MdOutlineCircle
                  className="h-5 w-5 cursor-pointer text-blue-500"
                  title="Approve Settlement"
                  onClick={() => {
                    handlePressonEye(info);
                  }}
                />
              ) : (
                <div className="text-red-500">
                  <FaCheckCircle
                    className="h-5 w-5 cursor-pointer text-blue-500"
                    title="Approved"
                  />
                </div>
              ))}
          </button>
          {roleData?.[0]?.settlement?.value?.download_settlement&&
            <SettlementReport
              settlementInfo={info}
              handleDownloadReport={handleDownloadReport}
              fetchAllsettlement={fetchAllsettlement}
            />
          }
          {roleData?.[0]?.settlement?.value?.edit_settlement && (
            <CrerateSettlementReport
              fetchAllsettlement={fetchAllsettlement}
              allOrgDetails={allOrgDetails}
              info={info}
            />
          )}
          {roleData?.[0]?.settlement?.value?.delete_settlement && (
            <MdDelete
              onClick={() => {
                // deleteSettlementReportFun(info.row.original?.id)
                setIsopenDelete(true);
                setDeleteRowId(info?.row?.original?.id);
              }}
              className="h-5 w-5 cursor-pointer font-bold text-red-500"
              title="Delete"
            />
          )}
        </p>
      ),
    }),
  ]; 

  const columns2 = [
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Organization Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.organization?.name}

        </div>
      ),
    }),
    columnHelper.accessor("payments", {
      id: "payments",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Client Name
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info?.row.original?.client
            ?.map((m: any) => {
              return m?.name;
            })
            .join(", ")}
        </div>
      ),
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Created Date
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info.row.original?.created_at}
        </div>
      ),
    }),
    columnHelper.accessor("payout", {
      id: "payout",
      header: () => (
        <p className="flex justify-start text-sm font-bold text-gray-900 dark:text-white">
          Total Payout
        </p>
      ),
      cell: (info: any) => (
        <div className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
          {info?.row?.original?.is_settlement_approved
            ? info.row.original?.total_payout ? "$" + Number(info.row.original?.total_payout)?.toFixed(2) : "" : "-"}
        </div>
      ),
    }),
 
  ]; 

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [searchVal, setSearchVal] = React.useState<any>("");
  const [data, setData] = React.useState(() => [...defaultData]);
  const [netPayout, setNetPayout] = React.useState({
    usdtForex: null,
    withdrawalFee: null,
    settlementFee: null,
    hash: null,
  });

  const [USDTForexRate, setUSDTForexRate] = React.useState<any>([]);
  console.log("USDTForexRate", USDTForexRate)
  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data,
    columns:(roleData?.[0]?.settlement?.value?.approve_settlement||roleData?.[0]?.settlement?.value?.download_settlement||roleData?.[0]?.settlement?.value?.edit_settlement||roleData?.[0]?.settlement?.value?.delete_settlement) ?columns:columns2,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleValueChange = (e: any) => {
    onValueChange(e);
    setSearchVal(e);
  };
  const handleApproveClose = () => {
    setUSDTForexRate([])
    setIsopenApprove(false);
    setNetPayout({
      usdtForex: null,
      withdrawalFee: null,
      settlementFee: null,
      hash: null,
    });
  };
  const handleClose = () => {
    setIsopenDelete(false);
    setDeleteRowId("");
  };

  const handlesubmitApprovedSettlement = () => {
    let payload = {
      USDT_forex_rate: USDTForexRate.map((mData: any) => {
        return {
          "currency": mData?.currency,
          "forex_rate": mData?.forex_rate
        }
      }),
      withdrawal_fees: netPayout?.withdrawalFee,
      settlement_fees: netPayout?.settlementFee,
      hash: netPayout?.hash,
      USDT_total_payout: (Number(USDTForexRate?.reduce((total: any, num: any) => {
        return Number(total) + Number(num?.netPayOut)
      }, 0)) - parseFloat(netPayout?.settlementFee || 0) - parseFloat(netPayout?.withdrawalFee || 0))?.toFixed(2) || ""
    };

    approvedSettlement(payload, TableApproveData?.row?.original?.id)
      .then((res) => {
        toast.success(res?.message);
        fetchAllsettlement();
        handleApproveClose();
      })
      .catch((error) => {
        toast.error(error?.message);
        fetchAllsettlement();
        handleApproveClose();
      });
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 py-4"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Settlement List
        </div>
        <Searchbox onSearch={handleValueChange} />
      </header>

      {isDownloadFile && (
        <div className="z-[1002]  mt-8 text-center text-blue-500">
          {"Please wait downloading...."}
        </div>
      )}

      <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
        {props.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              {table.getHeaderGroups().map((headerGroup: any) => (
                <tr
                  key={headerGroup.id}
                // className="!border-px !border-gray-400"
                >
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 p-2 pb-2 pr-4 pt-4 text-start"
                      >
                        <div className="items-center justify-between text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row: any) => {
                  return (
                    <tr
                      key={row.id}
                      className="border-b bg-white p-2 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                    >
                      {row.getVisibleCells().map((cell: any) => {
                        return (
                          <td
                            key={cell.id}
                            // className="min-w-[150px] border-white/0 p-2  py-3 pr-4"
                            className=" border-white/0 p-2  py-3 pr-4"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <p className="p-4 text-center">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <Modal isOpen={isOpenDelete} onClose={() => { }}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="top-[12vh] !z-[1002] !m-auto sm:my-8 sm:w-full sm:max-w-lg lg:max-w-3xl md:top-[11vh]"> */}
        <ModalContent className="sm:max-w-xxl z-[1002] !m-auto flex min-h-[100vh] max-w-[800px] justify-center !p-3 sm:my-8 sm:w-full">
          <ModalBody>
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">{"Confirm"}</h1>
              <p className="p-5 px-[30px] text-lg font-bold">
                {"Do you want to delete this record?"}
              </p>

              <div className="mt-5 flex justify-end gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-indigo-50  px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                {true && (
                  <button
                    onClick={deleteSettlementReportFun}
                    //   disabled={viewMode}
                    className="linear rounded-xl bg-red-100  px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-red-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                  >
                    {"Delete"}
                  </button>
                )}
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenApprove} onClose={() => { }}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        {/* <ModalContent className="top-[12vh] !z-[1002] !m-auto sm:my-8 sm:w-full sm:max-w-lg lg:max-w-3xl md:top-[11vh]"> */}
        <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[85vh] max-w-[800px]  justify-start overflow-auto sm:my-8 sm:w-full ">
          <ModalBody className="p-2">
            <Card extra=" max-w-[800px]  flex flex-col !z-[1004]">
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {TableApproveData?.row.original?.organization?.name + " - " + "Approve Settlement"}
              </h1>
              <div className="!max-h-[100vh] max-h-[calc(100vh-10vh)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1 sm:max-h-[calc(100vh-30vh)]">
                <div className="w-100 bg-white">
                  {
                    TableApproveData?.row.original?.settlement_history_details.map((sData: any) => {
                      console.log("sData", sData)
                      return <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg p-3">
                        <div className="mb-3">
                          <span className="font-bold my-1 text-gray-900 bg-gray-500 p-2">
                            {sData?.summary_currency}


                          </span>
                        </div>
                        <table className="w-full w-full text-left text-sm text-gray-900 dark:text-gray-400 rtl:text-right">
                          <thead className="bg-gray-50 text-xs uppercase text-gray-900 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              {[
                                "Total Volume",
                                "Total Deductions",
                                "Total Charges",
                                "Total Held",
                                "Total Reserve",
                                "Net Payout",
                                // "Total Payout",
                              ].map((mdata, index) => {
                                console.log("====================================");
                                console.log(mdata);
                                console.log("====================================");
                                return (
                                  <th
                                    key={index}
                                    className="cursor-pointer border-b-[1px] border-gray-200 p-2 pb-2 pr-4 pt-4 text-start"
                                  >
                                    <div className="items-center justify-between text-xs text-gray-900">
                                      {mdata}
                                    </div>
                                  </th>
                                );
                              })}
                            </tr>





                          </thead>
                          <tbody className="">
                            {/* {[TableApproveData?.row.original].map(
                                  (data: any, index: number) => {
                                    return ( */}
                            <tr
                              // key={index}

                              className="border-b bg-white p-2 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                            >
                              <td className=" border-white/0 p-2  px-4 py-3">

                                {`${sData?.summary_report_table_details?.total_volume_count}(${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.total_volume)?.toFixed(2)})`}
                              </td>
                              <td className=" border-white/0 p-2  px-4 py-3">
                                {/* {data?.total_deductions} */}
                                {`${sData?.summary_report_table_details?.total_deductions_count}(${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.total_deductions)?.toFixed(2)})`}

                              </td>{" "}
                              <td className=" border-white/0 p-2  px-4 py-3">
                                {/* {data?.total_charges} */}
                                {`${sData?.summary_report_table_details?.total_charges_count}(${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.total_charges)?.toFixed(2)})`}

                              </td>{" "}
                              <td className=" border-white/0 p-2  px-4 py-3">
                                {/* {data?.total_held} */}
                                {`${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.total_held)?.toFixed(2)}`}

                              </td>{" "}
                              <td className=" border-white/0 p-2  px-4 py-3">
                                {/* {data?.total_reserve} */}
                                {`${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.total_reserve)?.toFixed(2)}`}

                              </td>{" "}
                              <td className=" border-white/0 p-2  px-4 py-3">
                                {/* {data?.net_payout} */}
                                {`${sData?.currency_symbol || ""}${Number(sData?.summary_report_table_details?.net_payout)?.toFixed(2)}`}

                              </td>{" "}
                              {/* <td className=" border-white/0 p-2  px-4 py-3">
                                          {data?.total_payout}
                                        </td> */}
                            </tr>
                            {/* );
                                  }
                                )} */}
                          </tbody>
                        </table>

                        <div className="py-3">
                          <InputField
                            variant=""
                            extra="mt-3 w-full"
                            value={USDTForexRate?.filter((f: any) => f?.currency == sData?.summary_currency)[0]?.forex_rate}
                            label="USDT Forex Rate *"
                            placeholder="Enter USDT Forex Rate"
                            id={sData?.summary_currency}
                            type="Number"
                            onChange={(e) => {
                              const name = e.target.id
                              // const value=e.target.value

                              let value = e.target?.value;
                              value = Number(value)?.toFixed(2);
                              if (Number(value) >= 0 && Number(value) <= 100) {
                                setUSDTForexRate((prev: any) => {
                                  if (prev?.filter((f: any) => f.currency == name)?.length > 0) {
                                    return prev?.map((mData: any) => {
                                      if (mData?.currency == name) {
                                        return {
                                          ...mData,
                                          "currency": name,
                                          "forex_rate": Number(value),
                                          "netPayOut": Number(sData?.summary_report_table_details?.net_payout) * Number(value)

                                        }

                                      } else {
                                        return mData
                                      }

                                    })

                                  } else {
                                    let arr: any = []
                                    if (prev?.length > 0) {
                                      prev.forEach((element: any) => {
                                        arr.push(element)

                                      });

                                      return [...arr, {
                                        "currency": name,
                                        "forex_rate": Number(value),
                                        "netPayOut": Number(sData?.summary_report_table_details?.net_payout) * Number(value)

                                      }]

                                    } else {
                                      return [
                                        {
                                          "currency": name,
                                          "forex_rate": Number(value),
                                          "netPayOut": Number(sData?.summary_report_table_details?.net_payout) * Number(value)

                                        }
                                      ]
                                    }






                                  }


                                })

                              }


                            }}
                          />

                          <InputField
                            variant=""
                            extra="mt-3 w-full"
                            value={String(Number(USDTForexRate?.filter((f: any) => { return f?.currency == sData?.summary_currency })[0]?.netPayOut)?.toFixed(2))}
                            label="USDT Net Payout"
                            placeholder="USDT Net Payout"
                            id={sData?.summary_currency}
                            type="Number"
                            disabled
                          />


                        </div>
                      </div>


                    })
                  }

                </div>

                <div className="shadow-md py-3 rounded-lg mt-3 px-3" >
                  <div>
                    <span className="text-gray-900 px-2 font-bold p-2 bg-indigo-500 text-white">Summary</span>
                  </div>

                  <div className="w-100">
                    <InputField
                      variant=""
                      extra="mt-4 w-full"
                      value={
                        USDTForexRate?.reduce((total: any, num: any) => {
                          return Number(total) + Number(num?.netPayOut)
                        }, 0)?.toFixed(2)}
                      label="USDT Net Payout"
                      placeholder="USDT Net Payout"
                      id="settlementFee"
                      type="Number"
                      disabled
                    // onChange={(e) => {
                    //   const name = e.target.id;
                    //   let value = e.target?.value;
                    //   value = Number(value)?.toFixed(2);
                    //   if (Number(value) >= 0) {
                    //     setNetPayout((prev: any) => {
                    //       return {
                    //         ...prev,
                    //         [name]: Number(value),
                    //       };
                    //     });
                    //   }
                    // }}
                    />
                  </div>


                  <div className="w-100">
                    <InputField
                      variant=""
                      extra="mt-3 w-full"
                      value={netPayout?.settlementFee}

                      label="Settlement Fee*"
                      placeholder="Enter settlement fee"
                      id="settlementFee"
                      type="Number"
                      onChange={(e) => {
                        const name = e.target.id;
                        let value = e.target?.value;
                        value = Number(value)?.toFixed(2);
                        if (Number(value) >= 0) {
                          setNetPayout((prev: any) => {
                            return {
                              ...prev,
                              [name]: Number(value),
                            };
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="w-100">
                    <InputField
                      variant=""
                      extra="mt-3 w-full"
                      value={netPayout?.withdrawalFee}
                      label="Withdrawal Fee*"
                      placeholder="Enter withdrawal fee"
                      id="withdrawalFee"
                      type="Number"
                      onChange={(e) => {
                        const name = e.target.id;
                        let value = e.target?.value;
                        value = Number(value)?.toFixed(2);
                        if (Number(value) >= 0) {
                          setNetPayout((prev: any) => {
                            return {
                              ...prev,
                              [name]: Number(value),
                            };
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="w-100  ">


                    <InputField
                      variant=""
                      extra="mt-3 w-full"
                      // value={String(
                      //   Number(
                      //     parseFloat(
                      //       TableApproveData?.row.original?.net_payout || 0
                      //     ) *
                      //       parseFloat(netPayout?.usdtForex || 0) -
                      //       parseFloat(netPayout?.settlementFee || 0) -
                      //       parseFloat(netPayout?.withdrawalFee || 0)
                      //   )
                      // )}

                      value={
                        String((Number(USDTForexRate?.reduce((total: any, num: any) => {
                          return Number(total) + Number(num?.netPayOut)
                        }, 0)) - parseFloat(netPayout?.settlementFee || 0) - parseFloat(netPayout?.withdrawalFee || 0))?.toFixed(2))}
                      label="USDT Total Payout"
                      placeholder=""
                      id="Calculation"
                      disabled
                    />
                  </div>
                  <div className="w-100 ">
                    <InputField
                      variant=""
                      extra="mt-3 w-full"
                      value={netPayout.hash}
                      label="Hash *"
                      placeholder="Enter hash"
                      id="hash"
                      onChange={(e) => {
                        const name = e.target.id;
                        const value = e.target?.value;
                        setNetPayout((prev: any) => {
                          return {
                            ...prev,
                            [name]: value,
                          };
                        });
                      }}
                    />
                  </div>


                </div>



                <div className="mt-5 flex justify-end gap-2 px-[30px] pb-[30px]">
                  <button
                    onClick={handleApproveClose}
                    className="linear rounded-xl bg-indigo-50  px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                  >
                    Close
                  </button>
                  {true && (
                    <button
                      className="linear rounded-xl bg-indigo-50 px-3 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                      onClick={() => {
                        handlesubmitApprovedSettlement();
                      }}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default SettlementTable;
const columnHelper = createColumnHelper<SettlementRowObj>();
