import authRequest from "../utils/authRequest";


export const getAllBlackList = (
    page: number,
    pageSize: number = 50,
    searchTexts?: any
): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: `/api/v1/blacklist?page=${page}&per_page=${pageSize}&searchTexts=${searchTexts ? searchTexts : ""}`,
            method: "get",
            // params: params
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const createBlackListClient = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/blacklist",
            method: "post",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const deleteBlackListRecord = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/blacklist/" + id,
            method: "delete",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getBlackListRecordById = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/blacklist/" + id,
            method: "get",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getBlackListHistoryById = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/blacklisthistory/" + id,
            method: "get",
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};


export const updateBlackListRecord = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        authRequest({
            url: "/api/v1/blacklist/" + data?.id,
            method: "put",
            data,
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};





