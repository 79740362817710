import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import 'assets/css/dateRange.css';

interface DateRangeProps {
  onDateChange: (dateRange: [Date | null, Date | null]) => void;
  disabled?: boolean;
  id?: string;
  cStartdate?: any;
  cEnddate?: any;

}

const DateRange: React.FC<DateRangeProps> = ({ onDateChange, disabled, id, cStartdate, cEnddate }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    onDateChange(dateRange);
    //console.log("dateRange",dateRange);
  }, [dateRange]);




  return (
    <div>
      <DatePicker
        id={id}
        className={`flex h-10 w-full items-center text-gray-700 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none`}
        selectsRange={true}
        startDate={startDate || cStartdate}
        endDate={endDate || cEnddate}
        placeholderText="Select date range"
        onChange={(update: [Date, Date] | null) => {
          if (update) {
            setDateRange(update);
          }
        }}
        isClearable={true}
        disabled={disabled}
      />
    </div>
  );
};

export default DateRange;
