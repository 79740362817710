import { ClientContext } from "ClientProvider";
import { getSingleRole } from "api/role";
import { getProfile } from "api/users";
import avatar from "assets/img/avatars/avatar4.png";
import Dropdown from "components/dropdown";
import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { FiAlignJustify, FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearAllCookie, setId, setUserId } from "utils/auth";
import TripleToggleSwitch from "views/admin/payments/components/TripleToggleSwitch";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const navigate = useNavigate();
  const { onOpenSidenav, brandText } = props;

  const [darkmode, setDarkmode] = React.useState(false);
  const [storeName, setStoreName] = useState<any>();
  const {
    dashboardData,
    setDashboardData,
    setSingleRoleData,
    setFilter,
    filter,
  } = useContext(ClientContext);

  const location = useLocation();
  //console.log("location", location?.pathname?.split("/")?.[2]);

  const logout = () => {
    clearAllCookie();
    localStorage.clear()
    sessionStorage.clear();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    getProfile()
      .then((res) => {
        setStoreName(res[0]);
        setId(res?.[0]?.role_id);
        setUserId(res?.[0]?.id)
        // setId(res?.[0]?.role_id);
        getSingleRole(res?.[0]?.role_id)
          .then((data) => {
            setSingleRoleData(data ?? []);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.message ??
              "Something went wrong while fetching role"
            );
          })
          .finally(() => {
            // setIsLoading(false);
          });
      })
      .then((err) => {
        console.log("err", err);
      });
  }, []);

  // console.log('brandText', brandText)
  // console.log(storeName,"line38")

  const handleToggle = () => {
    setDashboardData(!dashboardData);
  };

  const labels = {
    left: {
      title: "All",
      value: "left",
    },
    right: {
      title: "Live",
      value: "right",
    },
    center: {
      title: "Test",
      value: "center",
    },
  };

  const onChange = (value: any) => {
    if (value === "left") {
      setFilter({ ...filter, status: "all" });
    } else if (value === "right") {
      setFilter({ ...filter, status: "live" });
    } else if (value === "center") {
      setFilter({ ...filter, status: "test" });
    }
  };

  return (
    // <nav className="sticky  top-4 !z-[10] flex flex-row flex-nowrap align-middle items-start justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
    <nav className="sticky  top-0 z-20 flex flex-row flex-nowrap align-middle items-start justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d] !bg-slate-500">
      {/* <nav className="sticky top-4 !z-[10] flex flex-row flex-wrap align-middle items-start justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]"> */}
      <div className="ml-[6px]">
        {/* <div className="h-6 w-[224px] pt-1">
          <Link
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="/admin/dashboard"
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </Link>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to={`/admin/${brandText?.toLowerCase()}`}
          >
            {brandText}
          </Link>
        </div> */}
        <div className="flex flex-col md:flex-row content-center gap-3">
          <p className=" shrink text-[33px] capitalize text-indigo-600 dark:text-white">
            <Link
              to="#"
              className="font-bold capitalize text-indigo-600 hover:text-indigo-800 dark:text-white"
            >
              {location?.pathname?.split("/")?.[2] === "smart-routing"
                ? "Smart routing"
                : brandText}
            </Link>
          </p>
          {/* {brandText === "Dashboard" && (
            <div className="mt-3 flex">
              <label className="relative mb-3 inline-flex cursor-pointer items-center">
                <input
                  type="checkbox"
                  className="peer sr-only"
                  // @ts-ignore
                  checked={dashboardData ? true : false}
                  onChange={handleToggle}
                />

                <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-indigo-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />

                <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300">
                  {dashboardData ? "Live" : "Test"}
                </span>
              </label>
            </div>
          )} */}
          {(brandText === "Transactions" || brandText === "Payout") && (
            <div className="mb-1 flex ">
              <label className="relative mb-3 inline-flex cursor-pointer items-center">
                <TripleToggleSwitch labels={labels} onChange={onChange} />
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="relative flex place-content-start items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-fit md:flex-grow-0 md:gap-1 xl:w-fit xl:gap-3" style={{ margin: "0px 10px" }}>
        {/* <div className="relative flex place-content-start items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none  md:w-fit md:flex-grow-0 md:gap-1 xl:w-fit xl:gap-3"> */}
        {/*<div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>*/}
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        <div
          className="cursor-pointer pl-1 text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-5 w-5 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-5 w-5 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full bg-indigo-500 dark:!border-navy-700">
              <p className="text-md text-white">
                {storeName?.first_name?.charAt(0)?.toUpperCase() +
                  storeName?.last_name?.charAt(0)?.toUpperCase()}
              </p>
              {/* <img className="h-full w-full rounded-full" src={avatar} alt="" /> */}
            </div>
            // <img
            //   className="h-12 w-12 cursor-pointer rounded-full"
            //   src={avatar}
            //   alt="Elon Musk"
            // />
          }
          children={
            <div className="h-42 mt-2 flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-3xl shadow-gray-200 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="ml-4 mt-3">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {storeName?.first_name} {storeName?.last_name}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="m-4 flex h-full flex-col justify-between gap-3">
                <a
                  href="/admin/profile"
                  className="w-full text-start text-sm text-gray-800 hover:text-indigo-500 hover:underline dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <button
                  onClick={logout}
                  className="w-fit rounded-xl bg-red-50 px-3 py-1 text-sm font-medium text-red-500 hover:text-red-500"
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav >
  );
};

export default Navbar;
